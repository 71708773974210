import { Table, Typography } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { ColumnsType } from "antd/lib/table";
import { DefaultButton } from "components/Buttons";
import Icon from "components/Icon";
import { tableColumnCommonData, sortingTableData, SortTypeEnum, SortOrderEnum } from "components/Table";
import DATE_FORMAT from "constants/dateFormat";
import dayjs from "dayjs";
import {
  DocumentRefetch,
  Documents,
  documentSharedWith,
  DocumentVisibilityType,
  FetchDocumentArg,
  FetchDocumentsResponse,
  ProfileDocumentResponse,
} from "model/Document";
import { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TABLE from "constants/table";
import { useAuthContext } from "contexts";
import { downloadLink } from "utils";
import styled from "styled-components";
import { FETCH_DOCUMENTS, FETCH_PROFILE_DOCUMENT } from "services/graphql/document";
import { useLazyQuery, useQuery } from "@apollo/client";
import { getFilenameFromUrl, removeExtensionFromName } from "../../leave";

type Props = {
  tableFor: "companyDocuments" | "employeeDocuments" | "allDocuments";
};

const DocumentTable = forwardRef<DocumentRefetch, Props>(({ tableFor }, ref) => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const userId = id || user?.id;
  const isMyProfile = userId === user?.id;
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Documents>>({});

  const [data, setData] = useState<Documents[]>([]);

  const { loading: documentLoading } = useQuery<ProfileDocumentResponse>(FETCH_PROFILE_DOCUMENT, {
    variables: { notActioned: false },
    onCompleted: (response) => {
      const documents: Documents[] = response.profileDocuments.documents.map((d: Documents) => ({ ...d })) ?? [];
      setData(documents);
    },
    fetchPolicy: "no-cache",
    skip: tableFor !== "allDocuments",
  });

  const [fetchDocuments, { loading }] = useLazyQuery<FetchDocumentsResponse>(FETCH_DOCUMENTS, {
    fetchPolicy: "no-cache",
  });

  const fetchEmployeeDocument = useCallback(
    async ({ cursor, accumulated, visibilityType }: FetchDocumentArg) => {
      const result = await fetchDocuments({
        variables: {
          first: TABLE.maxRowPerPage,
          after: cursor || undefined,
          userId,
          visibilityType,
        },
      });
      if (result.data) {
        const { nodes, pageInfo } = result?.data?.documents;
        const newData = [...(accumulated ?? []), ...nodes];
        if (pageInfo?.hasNextPage) {
          return fetchEmployeeDocument({ accumulated: newData, cursor: pageInfo.endCursor, visibilityType });
        }
        return newData;
      }
      return [];
    },
    [fetchDocuments, userId],
  );

  useLayoutEffect(() => {
    if (userId && tableFor !== "allDocuments") {
      fetchEmployeeDocument({
        visibilityType:
          tableFor === "companyDocuments" ? DocumentVisibilityType.Many : DocumentVisibilityType.Individual,
      }).then((data) => setData(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    documentRefetch() {
      fetchEmployeeDocument({ visibilityType: DocumentVisibilityType.Individual }).then((data) => setData(data));
    },
  }));

  const columns: ColumnsType<Documents> = [
    {
      ...tableColumnCommonData({
        title: "Document name",
        dataIndex: "attachmentName",
        defaultSortOrder: SortOrderEnum.Descend,
        width: 500,
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.String, firstData: a!.attachmentName, secondData: b!.attachmentName }),
      render: (_, { attachmentName }) => (
        <StyledDocumentName level={3} className="m-0 d-flex gap-2 py-2">
          <Icon name="document" />
          {removeExtensionFromName(attachmentName)}
        </StyledDocumentName>
      ),
    },
    ...(isMyProfile
      ? ([
          {
            ...tableColumnCommonData({
              title: "Shared with",
              dataIndex: "visibilityType",
              sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
            }),
            render: (_, { visibilityType }) => documentSharedWith[visibilityType],
            sorter: (a, b) =>
              sortingTableData({
                type: SortTypeEnum.String,
                firstData: documentSharedWith[a.visibilityType],
                secondData: documentSharedWith[b.visibilityType],
              }),
          },
        ] as ColumnsType<Documents>)
      : []),
    {
      ...tableColumnCommonData({
        title: "Date added",
        dataIndex: "dateAdded",
        width: !isMyProfile ? "45%" : undefined,
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { dateAdded }) => (dateAdded ? dayjs(dateAdded).format(DATE_FORMAT.fullDateDefault) : "N/A"),
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.dateAdded, secondData: b.dateAdded }),
    },
    {
      title: "",
      dataIndex: "Download",
      width: 100,
      align: "right",
      render: (_, { attachmentUrl }) => (
        <DefaultButton
          onClick={(event) => {
            event.stopPropagation();
            downloadLink(attachmentUrl, getFilenameFromUrl(attachmentUrl));
          }}
        >
          Download
        </DefaultButton>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading || documentLoading}
        rowKey="id"
        scroll={{
          scrollToFirstRowOnChange: false,
          y: tableFor === "allDocuments" ? undefined : TABLE.tableDefaultHeight,
        }}
        sortDirections={TABLE.tableSortDirections}
        onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<Documents>)}
        sticky={isMyProfile && tableFor === "allDocuments"}
      />
    </>
  );
});

export default DocumentTable;

const StyledDocumentName = styled(Typography.Title)`
  font-size: 14px;
`;
