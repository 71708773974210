import COLORS from "constants/colors";
import { EmployeeBreakdownResponse } from "model/Insights";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { PieSectorDataItem } from "recharts/types/polar/Pie";
import styled from "styled-components";

type Props = {
  chartData: EmployeeBreakdownResponse[];
  colors: string[];
  activeLabelIndex?: number;
  onMouseHoverHandler: (index: number) => void;
  onMouseLeaveHandler: () => void;
};

type customizedLabelProps = {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
  activeLabelIndex?: number;
  onMouseHoverHandler: (index: number) => void;
  onMouseLeaveHandler: () => void;
};

const customizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  activeLabelIndex,
  onMouseHoverHandler,
  onMouseLeaveHandler,
}: customizedLabelProps) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  if (index !== activeLabelIndex) return <></>;
  return (
    <StyledText
      x={x}
      y={y}
      fill={COLORS.white}
      textAnchor="middle"
      dominantBaseline="middle"
      onMouseEnter={() => onMouseHoverHandler(index)}
      onMouseLeave={() => onMouseLeaveHandler()}
    >
      {`${(percent * 100).toFixed(1)}%`}
    </StyledText>
  );
};

const renderActiveShape = (props: PieSectorDataItem) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, name } = props;

  const sin = Math.sin(-RADIAN * midAngle!);
  const cos = Math.cos(-RADIAN * midAngle!);
  const sx = cx! + (outerRadius! + 2) * cos;
  const sy = cy! + (outerRadius! + 2) * sin;
  const mx = cx! + (outerRadius! + 8) * cos;
  const my = cy! + (outerRadius! + 8) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 1;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius! + 2}
        outerRadius={outerRadius! + 5}
        fill={fill}
      />
    </g>
  );
};

const BreakdownPieChart = ({
  chartData,
  colors,
  onMouseHoverHandler,
  onMouseLeaveHandler,
  activeLabelIndex,
}: Props) => (
  <div>
    <ResponsiveContainer width="100%" height={275}>
      <PieChart>
        <Pie
          activeIndex={activeLabelIndex}
          data={chartData}
          labelLine={false}
          label={(props) => customizedLabel({ ...props, activeLabelIndex, onMouseHoverHandler, onMouseLeaveHandler })}
          cx="50%"
          cy="50%"
          innerRadius="35%"
          outerRadius="80%"
          dataKey="headCount"
          startAngle={90}
          endAngle={-270}
          animationBegin={100}
          animationDuration={700}
          onMouseEnter={(_, index) => onMouseHoverHandler(index)}
          onMouseLeave={() => onMouseLeaveHandler()}
          activeShape={renderActiveShape}
        >
          {chartData.map((_, index) => (
            <Cell stroke={colors[index]} key={`cell-${index + 1}`} fill={colors[index]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  </div>
);

export default BreakdownPieChart;

const StyledText = styled.text`
  font-size: 11px;
  font-weight: 700;
  fill: ${COLORS.white};

  &.active {
    font-size: 13px;
    fill: ${COLORS.inputFieldTextColor};
    font-weight: 600;
    line-height: 13px;
  }
`;
