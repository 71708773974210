import { gql } from "@apollo/client";
import { EventType, Period } from "model/Calendar";
import { ERROR_FRAGMENT, PAGE_INFO_FRAGMENT, WORKING_CONDITION_FRAGMENT } from "./common";

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    employeeNumber
    name
    email
    phone
    loggedIn
    profileImageUrl
    lineManagers {
      id
      name
    }
    departments {
      id
      name
    }
    jobTitle {
      id
      name
    }
    location {
      id
      name
    }
    startedAt
    roles {
      id
      name
    }
    leavingRequest {
      id
      leavingAt
      reason
    }
    workingCondition {
      ...WorkingConditionFragment
    }
  }
  ${WORKING_CONDITION_FRAGMENT}
`;

export const CREATE_USER = gql`
  mutation createUser(
    $departmentIds: [ID!]
    $email: String!
    $jobTitleId: ID
    $lineManagerIds: [ID!]
    $locationId: ID
    $name: String!
    $notifyUser: Boolean
    $startedAt: ISO8601Date
  ) {
    createUser(
      departmentIds: $departmentIds
      email: $email
      jobTitleId: $jobTitleId
      lineManagerIds: $lineManagerIds
      locationId: $locationId
      name: $name
      notifyUser: $notifyUser
      startedAt: $startedAt
    ) {
      errors {
        ...ErrorFragment
      }
      user {
        ...UserFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const FETCH_USER = gql`
  query users(
    $first: Int
    $after: String
    $searchValue: String
    $departmentIds: [ID!]
    $locationIds: [ID!]
    $showLeavers: Boolean
    $fetchBirthdaysThisWeek: Boolean!
    $fetchWorkAnniversaryThisWeek:Boolean!  
    $userIds:[ID!]
    $fetchHolidayBalance: Boolean = false
  ) {
    users(
      first: $first
      after: $after
      searchValue: $searchValue
      departmentIds: $departmentIds
      locationIds: $locationIds
      showLeavers: $showLeavers
      userIds:$userIds
    ) {
      edges {
        cursor
        node {
          id
          name
          email
          loggedIn
        }
      }
      nodes {
        holidayBalance @include(if: $fetchHolidayBalance) {
          holidaysPerYear
        }
        ...UserFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
    }

    birthdaysThisWeek: users(
      filterBy: [{ eventType: ${EventType.Birthday}, period: ${Period.Week} }]
      departmentIds: $departmentIds
      locationIds: $locationIds
    )  @include(if: $fetchBirthdaysThisWeek) {
      nodes {
        id
        dateOfBirth
      }
    }
    
    workAnniversaryThisWeek: users(
      filterBy: [{ eventType: ${EventType.Anniversary}, period: ${Period.Week} }]
      departmentIds: $departmentIds
      locationIds: $locationIds
    ) @include(if: $fetchWorkAnniversaryThisWeek) {
      nodes {
        id
        startedAt
      }
    }
  }
  ${USER_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const FETCH_LINE_MANAGERS = gql`
  query users($first: Int, $after: String, $roles: [UsersRoleEnum!]) {
    users(first: $first, after: $after, roles: $roles) {
      edges {
        cursor
        node {
          id
          name
          email
          loggedIn
        }
      }
      nodes {
        id
        name
        email
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const PERSONAL_DETAIL_FRAGMENT = gql`
  fragment PersonalDetailFragment on PersonalDetail {
    id
    name
    gender
    employeeNumber
    dateOfBirth
    address
    personalEmail
    personalPhone
    profileImageUrl
  }
`;

export const JOB_DETAIL_FRAGMENT = gql`
  fragment JobDetailFragment on JobDetail {
    id
    annualLeave
    contractType
    email
    endedAt
    holidaysPerYear
    departments {
      id
      name
    }
    jobTitle {
      id
      name
    }
    lineManagers {
      id
      name
    }
    location {
      id
      name
    }
    maxHolidaysFromLastYear
    phone
    probationaryPeriod
    startedAt
    workingCondition {
      ...WorkingConditionFragment
    }
    accumulatedCarriedHolidays
  }
  ${WORKING_CONDITION_FRAGMENT}
`;

export const EMERGENCY_CONTACT_FRAGMENT = gql`
  fragment EmergencyContactFragment on EmergencyContact {
    id
    name
    email
    phone
    address
    relationship
  }
`;

export const SALARY_FRAGMENT = gql`
  fragment SalaryFragment on Salary {
    id
    amount
    currency
    paymentType
  }
`;

export const FETCH_EMPLOYEE_FULL_DETAIL = gql`
  query fetchEmployeeFullDetail(
    $userId: ID!
    $hasFetchPersonalDetail: Boolean!
    $hasFetchJobDetail: Boolean!
    $hasFetchEmergencyContact: Boolean!
    $hasFetchSalary: Boolean!
  ) {
    fetchPersonalDetail(userId: $userId) @skip(if: $hasFetchPersonalDetail) {
      ...PersonalDetailFragment
    }
    fetchJobDetail(userId: $userId) @skip(if: $hasFetchJobDetail) {
      ...JobDetailFragment
    }
    fetchEmergencyContact(userId: $userId) @skip(if: $hasFetchEmergencyContact) {
      ...EmergencyContactFragment
    }
    fetchSalary(userId: $userId) @skip(if: $hasFetchSalary) {
      ...SalaryFragment
    }
  }
  ${PERSONAL_DETAIL_FRAGMENT}
  ${JOB_DETAIL_FRAGMENT}
  ${EMERGENCY_CONTACT_FRAGMENT}
  ${SALARY_FRAGMENT}
`;

export const FETCH_PROFILE_FULL_DETAILS = gql`
  query fetchProfileOverview {
    fetchProfileOverview: me {
      fetchPersonalDetail: personalDetail {
        ...PersonalDetailFragment
      }
      fetchJobDetail: jobDetail {
        ...JobDetailFragment
      }
      fetchEmergencyContact: emergencyContact {
        ...EmergencyContactFragment
      }
      fetchSalary: salary {
        ...SalaryFragment
      }
    }
  }
  ${PERSONAL_DETAIL_FRAGMENT}
  ${JOB_DETAIL_FRAGMENT}
  ${EMERGENCY_CONTACT_FRAGMENT}
  ${SALARY_FRAGMENT}
`;

export const FETCH_MY_PROFILE_EDIT_DETAIL = gql`
  query fetchEditProfileOverview {
    fetchEditProfileOverview: me {
      fetchPersonalDetail: personalDetail {
        ...PersonalDetailFragment
      }

      fetchEmergencyContact: emergencyContact {
        ...EmergencyContactFragment
      }
    }
  }
  ${PERSONAL_DETAIL_FRAGMENT}
  ${EMERGENCY_CONTACT_FRAGMENT}
`;

export const UPSERT_EMPLOYEE_DETAILS = (
  hasUpsertPersonalDetail: boolean,
  hasUpsertJobDetail: boolean,
  hasUpsertEmergencyContact: boolean,
  hasUpsertSalary: boolean,
) => {
  const mutation = `
  mutation upsertEmployeeDetail(
    $userId: ID!
    ${
      hasUpsertPersonalDetail
        ? `
      $address: String
      $dateOfBirth: ISO8601Date
      $employeeNumber: Int
      $gender: UsersGenderEnum
      $name: String!
      $personalEmail: String
      $personalPhone: String
      `
        : ""
    }
    ${
      hasUpsertJobDetail
        ? `
    $annualLeave: DefaultSettingsAnnualLeaveEnum!
    $contractType: JobDetailsContractTypeEnum
    $departmentIds: [ID!]
    $email: String
    $endedAt: ISO8601Date
    $holidaysPerYear: Float!
    $jobTitleId: ID
    $lineManagerIds: [ID!]
    $locationId: ID
    $maxHolidaysFromLastYear: Float!
    $phone: String
    $probationaryPeriod: Int
    $startedAt: ISO8601Date
    $workingDays: [WorkingDayAttributes!]!
    $workingTime: WorkingConditionsWorkingTimeEnum!
    $accumulatedCarriedHolidays: Float
    `
        : ""
    }
    ${
      hasUpsertEmergencyContact
        ? `
    $emergencyAddress: String
    $emergencyEmail: String
    $emergencyName: String
    $emergencyPhone: String
    $emergencyRelationship: String
       `
        : ""
    }
  
    ${
      hasUpsertSalary
        ? `
    $amount: Int
    $currency: SalariesCurrencyEnum
    $paymentType: SalariesPaymentTypeEnum`
        : ""
    }
 
  ) {
    ${
      hasUpsertPersonalDetail
        ? `upsertPersonalDetail(
          userId: $userId
          address: $address
          dateOfBirth: $dateOfBirth
          employeeNumber: $employeeNumber
          gender: $gender
          name: $name
          personalEmail: $personalEmail
          personalPhone: $personalPhone
        ) {
          errors {
            ...ErrorFragment
          }
          personalDetail {
            ...PersonalDetailFragment
          }
        }`
        : ""
    }
    ${
      hasUpsertJobDetail
        ? `upsertJobDetail(
          userId: $userId
          annualLeave: $annualLeave
          contractType: $contractType
          departmentIds: $departmentIds
          email: $email
          endedAt: $endedAt
          holidaysPerYear: $holidaysPerYear
          jobTitleId: $jobTitleId
          lineManagerIds: $lineManagerIds
          locationId: $locationId
          maxHolidaysFromLastYear: $maxHolidaysFromLastYear
          accumulatedCarriedHolidays: $accumulatedCarriedHolidays
          phone: $phone
          probationaryPeriod: $probationaryPeriod
          startedAt: $startedAt
          workingDays: $workingDays
          workingTime: $workingTime
        ) {
          errors {
            ...ErrorFragment
          }
          jobDetail {
            ...JobDetailFragment
          }
        }`
        : ""
    }
    ${
      hasUpsertEmergencyContact
        ? `upsertEmergencyContact(
          userId: $userId
          address: $emergencyAddress
          email: $emergencyEmail
          name: $emergencyName
          phone: $emergencyPhone
          relationship: $emergencyRelationship
        ) {
          errors {
            ...ErrorFragment
          }
          emergencyContact {
            ...EmergencyContactFragment
          }
        }`
        : ""
    }
    ${
      hasUpsertSalary
        ? `upsertSalary(
          userId: $userId
          amount: $amount
          currency: $currency
          paymentType: $paymentType
        ) {
          errors {
            ...ErrorFragment
          }
          salary {
            ...SalaryFragment
          }
        }`
        : ""
    }
  }
`;

  return gql`
    ${mutation}
    ${hasUpsertPersonalDetail ? PERSONAL_DETAIL_FRAGMENT : ``}
    ${hasUpsertJobDetail ? JOB_DETAIL_FRAGMENT : ``}
    ${hasUpsertEmergencyContact ? EMERGENCY_CONTACT_FRAGMENT : ``}
    ${hasUpsertSalary ? SALARY_FRAGMENT : ``}
    ${ERROR_FRAGMENT}
  `;
};

export const UPSERT_MY_PROFILE_DETAIL = gql`
  mutation upsertMyProfileDetail(
    $userId: ID!
    $address: String
    $dateOfBirth: ISO8601Date
    $employeeNumber: Int
    $gender: UsersGenderEnum
    $name: String!
    $personalEmail: String
    $personalPhone: String
    $emergencyAddress: String
    $emergencyEmail: String
    $emergencyName: String
    $emergencyPhone: String
    $emergencyRelationship: String
  ) {
    upsertPersonalDetail(
      userId: $userId
      address: $address
      dateOfBirth: $dateOfBirth
      employeeNumber: $employeeNumber
      gender: $gender
      name: $name
      personalEmail: $personalEmail
      personalPhone: $personalPhone
    ) {
      errors {
        ...ErrorFragment
      }
      personalDetail {
        ...PersonalDetailFragment
      }
    }
    upsertEmergencyContact(
      userId: $userId
      address: $emergencyAddress
      email: $emergencyEmail
      name: $emergencyName
      phone: $emergencyPhone
      relationship: $emergencyRelationship
    ) {
      errors {
        ...ErrorFragment
      }
      emergencyContact {
        ...EmergencyContactFragment
      }
    }
  }
  ${PERSONAL_DETAIL_FRAGMENT}
  ${EMERGENCY_CONTACT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

export const UPSERT_PROFILE_IMAGE = gql`
  mutation upsertProfileImage($profileImage: Upload!, $userId: ID!) {
    upsertProfileImage(profileImage: $profileImage, userId: $userId) {
      user {
        id
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const DESTROY_PROFILE_IMAGE = gql`
  mutation destroyProfileImage($userId: ID!) {
    destroyProfileImage(userId: $userId) {
      success
    }
  }
`;

export const EMPLOYEE_REPORTS = gql`
  query exportUsers($departmentIds: [ID!], $locationIds: [ID!], $searchValue: String, $showLeavers: Boolean) {
    exportUsers(
      departmentIds: $departmentIds
      locationIds: $locationIds
      searchValue: $searchValue
      showLeavers: $showLeavers
    ) {
      presignedUrl
    }
  }
`;

export const DESTROY_USER = gql`
  mutation destroyUser($id: ID!) {
    destroyUser(id: $id) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }
  ${ERROR_FRAGMENT}
`;

export const IMPORT_EMPLOYEE = gql`
  mutation bulkImport($uploadAttachment: Upload!) {
    bulkImport(uploadAttachment: $uploadAttachment) {
      importedRecords
    }
  }
`;

export const CALCULATE_DAYS_OFF = gql`
  mutation calculateDaysOff($leavingAt: ISO8601Date!, $userId: ID!) {
    calculateDaysOff(leavingAt: $leavingAt, userId: $userId) {
      remainingDays
    }
  }
`;

export default {
  CREATE_USER,
  FETCH_USER,
  UPSERT_EMPLOYEE_DETAILS,
  FETCH_MY_PROFILE_EDIT_DETAIL,
  UPSERT_MY_PROFILE_DETAIL,
  UPSERT_PROFILE_IMAGE,
  DESTROY_PROFILE_IMAGE,
  EMPLOYEE_REPORTS,
  IMPORT_EMPLOYEE,
  CALCULATE_DAYS_OFF,
};
