import { Typography } from "antd";
import { Container } from "components/core";
import styled from "styled-components";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { DocumentRefetch } from "model/Document";
import { useAuthContext } from "contexts";
import { useParams } from "react-router-dom";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import { isAdmin, isManager } from "model/User";
import { includesInArray } from "utils";
import DocumentTable from "./DocumentTable";
import LeaveDocumentTable from "./LeaveDocumentTable";

const DocumentTab = forwardRef<DocumentRefetch>((_, ref) => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const userId = id || user?.id;
  const isMyProfile = userId === user?.id;
  const { employeeLeaveDocumentTable } = PERMISSION;
  const { hasEmployeeLeaveDocumentTable } = useCheckPermission({
    employeeLeaveDocumentTable,
  });
  const employeeDocumentTableRef = useRef<DocumentRefetch>(null);

  useImperativeHandle(ref, () => ({
    documentRefetch() {
      employeeDocumentTableRef.current?.documentRefetch();
    },
  }));

  return (
    <StyledWrapper>
      <Container className="p-0 section-box-shadow">
        <Typography.Title level={2} className="p-4 m-0 table-title">
          This employee’s documents
        </Typography.Title>
        <DocumentTable tableFor="employeeDocuments" ref={employeeDocumentTableRef} />
      </Container>
      <Container className="p-0 mt-4 section-box-shadow">
        <Typography.Title level={2} className="p-4 m-0 table-title">
          Company documents
        </Typography.Title>
        <DocumentTable tableFor="companyDocuments" />
      </Container>

      {id &&
        (isMyProfile ||
          (isAdmin(user) && !isManager(user)) ||
          (isManager(user) && includesInArray(user?.managedUserIds, id) && hasEmployeeLeaveDocumentTable)) && (
          <Container className="p-0 mt-4 section-box-shadow">
            <Typography.Title level={2} className="p-4 m-0 table-title">
              Leave documents
            </Typography.Title>
            <LeaveDocumentTable />
          </Container>
        )}
    </StyledWrapper>
  );
});

export default DocumentTab;

const StyledWrapper = styled.div`
  .ant-table-column-sorter {
    display: none;
  }
`;
