import { useLazyQuery, useMutation } from "@apollo/client";
import { Col, FormInstance, Modal, Row, Typography } from "antd";
import { BaseButton, SuccessButton } from "components/Buttons";
import Icon from "components/Icon";
import PERMISSION from "config/permission";
import { useDebounce } from "hooks";
import { downloadLink } from "utils";
import useCheckPermission from "hooks/useCheckPermission";
import useCreateLeaveRequest from "hooks/useCreateLeaveRequest";
import useFormModal from "hooks/useFormModal";
import { CreateEmployeeResponse, EmployeeRefetch, EmployeeReportsResponse, FilterType } from "model/Employee";
import { LeaveRequestArg } from "model/Leave";
import { useRef, useState } from "react";
import { useNotify } from "services/api";
import { CREATE_USER, EMPLOYEE_REPORTS } from "services/graphql/employee";
import { useAuthContext } from "contexts";
import LeaveForm from "../users/LeaveForm";
import AddEmployeeForm from "./employeeList/AddEmployeeForm";
import EmployeeList from "./employeeList/EmployeeList";
import ImportEmployeeForm from "./employeeList/ImportEmployeeForm";
import ListFilter from "./employeeList/ListFilter";

const Employees = () => {
  const { createEmployee, recordLeave, importEmployee, employeeReports } = PERMISSION;
  const { hasCreateEmployee, hasRecordLeave, hasEmployeeReports, hasImportEmployee } = useCheckPermission({
    createEmployee,
    recordLeave,
    importEmployee,
    employeeReports,
  });
  const { isModalVisible, showModal, hideModal, onCloseModal, onFieldsChange } = useFormModal();
  const importFormRef = useRef<FormInstance>(null);
  const onCloseModalCallback = () => importFormRef.current?.resetFields();
  const employeeListRef = useRef<EmployeeRefetch>(null);
  const [filterValue, setFilterValue] = useState<FilterType>();
  const [id, setId] = useState<string>();
  const searchDeferredValue = useDebounce<FilterType | undefined>(filterValue);
  const isStale = filterValue !== searchDeferredValue;
  const notify = useNotify();
  const { refetch } = useAuthContext();

  const {
    isModalVisible: isRecordLeaveModalVisible,
    hideModal: hideRecordLeaveModal,
    showModal: showRecordLeaveModal,
    onCloseModal: onCloseLeaveModal,
    onFieldsChange: onRecordLeaveFieldsChange,
  } = useFormModal();

  const {
    isModalVisible: isEmployeeReportModalVisible,
    hideModal: hideEmployeeReportModal,
    showModal: showEmployeeReportModal,
  } = useFormModal();

  const {
    isModalVisible: isImportModalVisible,
    hideModal: hideImportModal,
    showModal: showImportModal,
    onCloseModal: onCloseImportModal,
    onFieldsChange: onImportFieldsChange,
  } = useFormModal({ onCloseModalCallback });

  const [createUser, { loading }] = useMutation<CreateEmployeeResponse>(CREATE_USER, {
    onCompleted: (response) => {
      if (response?.createUser) {
        if (response?.createUser?.errors?.fullMessages?.length) {
          response.createUser.errors.fullMessages.map((error: string) => notify.error(undefined, error));
        } else {
          notify.success({ message: "Created employee successfully." });
          employeeListRef.current?.refetch();
          refetch();
          hideModal();
        }
      }
    },
  });

  const { mutate, loading: recordLeaveLoading } = useCreateLeaveRequest(id, () => hideRecordLeaveModal(), "record");

  const [exportReports, { loading: exportReportsLoading }] = useLazyQuery<EmployeeReportsResponse>(EMPLOYEE_REPORTS, {
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      if (response?.exportUsers?.presignedUrl) {
        downloadLink(response.exportUsers.presignedUrl, "employee_report.csv", false);
        hideEmployeeReportModal();
      }
    },
  });

  return (
    <div id="main" className="h-100">
      <Row className="mb-4 pb-1" id="employee_header">
        <Col lg={4} xl={5} className="d-flex align-items-center">
          <Typography.Title level={1} className="m-0">
            Employees
          </Typography.Title>
        </Col>
        <Col lg={14} xl={15} className="text-end">
          {hasCreateEmployee && (
            <SuccessButton className="mx-2" onClick={showModal}>
              Add employee
            </SuccessButton>
          )}
          {hasRecordLeave && (
            <BaseButton className="mx-1 border" onClick={showRecordLeaveModal}>
              Record leave
            </BaseButton>
          )}
          {hasImportEmployee && (
            <BaseButton className="mx-2 border" onClick={showImportModal}>
              Import employees
            </BaseButton>
          )}
        </Col>
        {hasEmployeeReports && (
          <Col lg={6} xl={4} className="text-end">
            <BaseButton className="border" onClick={showEmployeeReportModal}>
              Employee reports <Icon name="download" />
            </BaseButton>
          </Col>
        )}
      </Row>
      <ListFilter onChange={(data) => setFilterValue(data)} />
      <div
        style={{
          opacity: isStale ? 0.5 : 1,
          transition: isStale ? "opacity 0.2s 0.2s linear" : "opacity 0s 0s linear",
        }}
        className="mt-4 section-box-shadow"
      >
        <EmployeeList filterValue={searchDeferredValue} ref={employeeListRef} />
      </div>
      <Modal
        title="Add new employee"
        open={isModalVisible}
        onCancel={onCloseModal}
        footer={null}
        width={430}
        destroyOnClose
        centered
      >
        <AddEmployeeForm
          onFieldsChange={onFieldsChange}
          onFinish={(data) => createUser({ variables: data })}
          loading={loading}
        />
      </Modal>
      <Modal
        title="Record leave"
        open={isRecordLeaveModalVisible}
        onCancel={onCloseLeaveModal}
        footer={null}
        width={430}
        destroyOnClose
        centered
      >
        <LeaveForm
          leaveFormType="record"
          onFieldsChange={onRecordLeaveFieldsChange}
          onSubmit={({ user: userId, ...leaveRequest }: LeaveRequestArg) =>
            mutate({ variables: { ...leaveRequest, userId } })
          }
          setUserIdForRecordLeave={(value: string) => setId(value)}
          loading={recordLeaveLoading}
          isUserLeft={searchDeferredValue?.showLeavers}
        />
      </Modal>
      <Modal
        title="Import employees"
        open={isImportModalVisible}
        onCancel={onCloseImportModal}
        footer={null}
        width={450}
        centered
      >
        <ImportEmployeeForm
          onCancel={() => {
            hideImportModal();
            onImportFieldsChange(false);
          }}
          onFieldsChange={onImportFieldsChange}
          refetchEmployeeList={() => employeeListRef.current?.refetch()}
          ref={importFormRef}
        />
      </Modal>
      <Modal
        title="Employee report"
        open={isEmployeeReportModalVisible}
        onCancel={hideEmployeeReportModal}
        footer={null}
        centered
        width={300}
      >
        <SuccessButton
          htmlType="submit"
          className="w-100"
          onClick={() =>
            exportReports({
              variables: {
                searchValue: searchDeferredValue?.searchValue,
                departmentIds: searchDeferredValue?.departments,
                locationIds: searchDeferredValue?.locations,
                showLeavers: searchDeferredValue?.showLeavers,
              },
            })
          }
          loading={exportReportsLoading}
        >
          Download to Excel
        </SuccessButton>
      </Modal>
    </div>
  );
};

export default Employees;
