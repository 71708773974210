import { PageInfo, ValidationErrors } from "./Common";
import { BookingStatus, LeaveRequestsReason, LeaveRequestsStatus, Note } from "./Leave";

export enum DocumentVisibilityType {
  Individual = "INDIVIDUAL_EMPLOYEE",
  Many = "MANY_EMPLOYEES",
}

export const documentShortVisibilityTypeLabel: Record<DocumentVisibilityType, string> = {
  [DocumentVisibilityType.Individual]: "Individual",
  [DocumentVisibilityType.Many]: "Many",
};

export const documentVisibilityLabel: Record<DocumentVisibilityType, string> = {
  [DocumentVisibilityType.Individual]: "Private, to be shared with only one employee",
  [DocumentVisibilityType.Many]: "Company-wide, to be shared with many",
};

export interface DocumentAction {
  id: string;
  deadlineAt: string;
  description: string;
}

export enum ShareType {
  CurrentEmployee = "CURRENT_EMPLOYEES",
  NewEmployee = "NEW_EMPLOYEES",
  BothEmployee = "NEW_AND_CURRENT_EMPLOYEES",
}

export const documentShareTypeLabel: Record<ShareType, string> = {
  [ShareType.NewEmployee]: "All new employees",
  [ShareType.CurrentEmployee]: "All current employees",
  [ShareType.BothEmployee]: "Both current and new employees",
};

export interface Documents {
  id: string;
  action: DocumentAction | null;
  attachmentName: string;
  attachmentUrl: string;
  actionCompletedCount: number | null;
  actionPendingCount: number | null;
  dateAdded: string;
  shareType: ShareType;
  sharedWithCount: number;
  visibilityType: DocumentVisibilityType;
  userDocumentConnections?: {
    user: {
      id: string;
      name: string;
    };
  }[];
}

export interface FetchDocumentArg {
  accumulated?: Documents[] | null;
  cursor?: string | null;
  visibilityType: DocumentVisibilityType;
}

export interface UserActionConnection {
  dateActioned: string;
  id: string;
}
export interface UserDocumentConnections {
  userActionConnection: UserActionConnection | null;
  dateAdded: string;
  id: string;
  user: {
    id: string;
    name: string;
  };
}
export interface DocumentDetail extends Documents {
  userDocumentConnections: UserDocumentConnections[];
}

export interface DocumentDetailResponse {
  fetchDocument: DocumentDetail;
}

export interface UpdateDocument extends DocumentDetail {
  errors: ValidationErrors;
}

export interface UpdateDocumentResponse {
  updateDocument: UpdateDocument;
}

export interface FetchDocumentsResponse {
  documents: {
    edges: Object;
    nodes: Documents[];
    pageInfo: PageInfo;
  };
}

export enum Actioned {
  Yes = "YES",
  Pending = "PENDING",
}

export const actionedLabel: Record<Actioned, string> = {
  [Actioned.Yes]: "Yes",
  [Actioned.Pending]: "Pending",
};

export interface UploadDocumentResponse {
  createDocument: {
    document: Documents;
    errors: ValidationErrors;
  };
}

export interface DocumentReviewDataType
  extends Pick<Documents, "id" | "action" | "attachmentName" | "attachmentUrl" | "dateAdded"> {
  createdAt: string;
}
export interface DocumentReviewResponse {
  documentReview: {
    documents: DocumentReviewDataType[];
  };
}

export interface ProfileDocumentResponse {
  profileDocuments: {
    documents: DocumentDetail[];
  };
}

export interface UploadDocumentFormFirstStepArg {
  attachment: string;
  visibilityType: DocumentVisibilityType;
  userIds?: string;
  shareType?: ShareType;
  sharedWithEmployee?: string;
}
export interface UploadDocumentFormFirstStepData
  extends Omit<UploadDocumentFormFirstStepArg, "attachment" | "userIds"> {
  attachment?: File | null;
  userIds?: string[];
}

export interface UploadDocumentFormData extends UploadDocumentFormFirstStepData {
  actionDescription?: string;
  actionDeadlineDate?: string;
}

export enum DocumentShareOn {
  ShareOnProfile = "SHARE_ON_PROFILE",
  ShareProfileAndEmail = "SHARE_PROFILE_AND_EMAIL",
}

export const documentShareOnLabel: Record<DocumentShareOn, string> = {
  [DocumentShareOn.ShareOnProfile]: "Share on profile",
  [DocumentShareOn.ShareProfileAndEmail]: "Share on profile and by email",
};

export const documentSharedWith: Record<DocumentVisibilityType, string> = {
  [DocumentVisibilityType.Individual]: "Just me",
  [DocumentVisibilityType.Many]: "Many",
};

export interface DocumentActionDataModify {
  id: string;
  name: string;
  action?: string;
  actionPending: number | null;
  deadlineDate?: string;
}
export interface DocumentRefetch {
  documentRefetch(): void;
}

export interface DestroyDocument {
  destroyDocument: { success: boolean };
}

export interface DestroyDocumentAttachedWithLeaveResponse {
  updateLeaveRequest: {
    errors: ValidationErrors;
    leaveRequest: {
      id: string;
      attachmentUrl: string | null;
    };
  };
}

export interface SendDocumentReminderResponse {
  sendDocumentReminder: {
    success: boolean;
  };
}

export interface DeleteAccessResponse {
  destroyUserDocumentConnection: {
    success: boolean;
  };
}

export interface ActionCompletedResponse {
  createUserActionConnection: {
    errors: ValidationErrors;
  };
}

export interface CreateDocumentAccessResponse {
  createUserDocumentConnection: {
    importedRecordsCount: number;
  };
}

export interface DocumentsAssociatedWithLeave {
  id: string;
  status: LeaveRequestsStatus;
  bookingStatus: BookingStatus | null;
  attachmentUrl: string;
  attachmentName: string;
  dateAdded: string;
  user: {
    id: string;
    name: string;
  };
  reason?: LeaveRequestsReason;
  fromDate?: string;
  toDate?: string;
  employeeNote?: Note | null;
}

export interface DocumentAssociatedWithLeaveResponse {
  documentsAssociatedWithLeave: {
    edges: Object;
    nodes: DocumentsAssociatedWithLeave[];
    pageInfo: PageInfo;
  };
}
