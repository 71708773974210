import { useMutation } from "@apollo/client";
import { useAuthContext } from "contexts";
import { DestroyProfileImageResponse, UpsertProfileImageResponse } from "model/Employee";
import { useNavigate, useParams } from "react-router-dom";
import { useNotify } from "services/api";
import { DESTROY_PROFILE_IMAGE, FETCH_USER, UPSERT_PROFILE_IMAGE } from "services/graphql/employee";

export const useUploadProfileImage = (isFromEditPage?: boolean) => {
  const notify = useNotify();
  const navigate = useNavigate();
  const { refetch, user } = useAuthContext();
  const { id } = useParams();
  const userId = id || user?.id;
  const isMyProfile = userId === user?.id;

  const [mutate, { loading: uploading }] = useMutation<UpsertProfileImageResponse>(UPSERT_PROFILE_IMAGE, {
    onCompleted: (response) => {
      const { upsertProfileImage } = response;
      if (upsertProfileImage?.errors) {
        notify.error(upsertProfileImage.errors.fullMessages);
        return;
      }
      if (upsertProfileImage?.user) {
        if (isMyProfile) {
          refetch();
        }
        if (isFromEditPage) {
          if (id) {
            notify.success({ message: "Update employee detail successfully." });
          } else {
            notify.success({ message: "Update profile successfully." });
          }
          navigate(-1);
        } else {
          notify.success({ message: "Update profile picture successfully." });
        }
      }
    },

    refetchQueries: [
      {
        query: FETCH_USER,
        variables: {
          userIds: [userId],
          fetchBirthdaysThisWeek: false,
          fetchWorkAnniversaryThisWeek: false,
          showLeavers: true,
        },
      },
    ],
  });

  const [mutateDestroyProfile, { loading: removing }] = useMutation<DestroyProfileImageResponse>(
    DESTROY_PROFILE_IMAGE,
    {
      onCompleted: (response) => {
        const { destroyProfileImage } = response;
        if (!destroyProfileImage?.success) {
          notify.error(undefined, "Remove profile picture failed.");
          return;
        }

        if (isMyProfile) {
          refetch();
        }
        if (isFromEditPage) {
          if (id) {
            notify.success({ message: "Update employee detail successfully." });
          } else {
            notify.success({ message: "Update profile successfully." });
          }
          navigate(-1);
        } else {
          notify.success({ message: "Removed profile picture successfully." });
        }
      },
      refetchQueries: [
        {
          query: FETCH_USER,
          variables: {
            userIds: [userId],
            fetchBirthdaysThisWeek: false,
            fetchWorkAnniversaryThisWeek: false,
            showLeavers: true,
          },
        },
      ],
    },
  );

  return {
    mutateDestroyProfile,
    mutate,
    loading: uploading || removing,
  };
};

export default useUploadProfileImage;
