import { CheckboxOptionType } from "antd";
import dayjs from "dayjs";
import { ImportEmployeeNotifyPoints } from "model/Employee";
import { LeaveNotificationMsgArg, NotificationMsg, OtherNotificationMsgArg } from "model/Notification";
import { WeekDayEnum } from "model/Common";
import DATE_FORMAT from "./dateFormat";

const databaseFields: Record<string, string> = {
  employeeNumber: "Employee number",
  name: "Name",
  gender: "Gender",
  personalPhone: "Personal phone",
  personalEmail: "Personal email",
  dateOfBirth: "Date of birth",
  address: "Address",
  jobTitle: "Job Title",
  department: "Department",
  lineManager: "Line Manager",
  location: "Work Location",
  startDate: "Start date",
  endDate: "End date (if applicable)",
  probationaryPeriod: "Probationary period",
  holidaysPerAnnum: "Number of holidays per annum",
  contractType: "Contract type",
  hoursPerWeek: "Hours per week",
  startTime: "Start time",
  endTime: "End time",
  daysPerWeek: "Days per week",
  workPhoneNumber: "Work phone number",
  workEmail: "Work email",
  emergencyName: "Emergency contact name",
  emergencyRelationship: "Emergency contact relationship",
  emergencyAddress: "Emergency contact address",
  emergencyPhoneNumber: "Emergency contact phone number",
  emergencyEmail: "Emergency contact email",
  salary: "Salary - amount",
  currency: "Currency",
  type: "Type",
};

const employeePersonalDetails: Record<string, string> = {
  firstName: "First name",
  preferredName: "Preferred name",
  lastName: "Last name",
  dateOfBirth: "Date of birth",
  nationality: "Nationality",
  gender: "Gender",
  workEmail: "Work email",
  workPhone: "Work phone",
  personalEmail: "Personal email",
  personalPhone: "Personal phone",
  fullAddress: "Full address",
  country: "Country",
  dietaryPreference: "Dietary preference",
  foodAllergies: "Food allergies",
  emergencyContacts: "Emergency contacts",
};

const employment: Record<string, string> = {
  jobTitle: "Job title",
  department: "Department",
  location: "Location",
  manager: "Manager",
  managerEmail: "Manager email",
  roleEffectiveDate: "Role effective date",
  contractType: "Contract type",
  employeeID: "EmployeeID",
  employmentStart: "Employment start",
  firstWorkingDay: "First working day",
  probationEnd: "Probation end",
  employmentEnd: "Employment end",
};

const compensation: Record<string, string> = {
  salary: "Salary",
};

const leave: Record<string, string> = {
  paidTimeOff: "Paid time off",
  unpaidLeave: "Unpaid leave",
  sickLeave: "Sick leave",
  parentalLeave: "Parental leave",
  otherTimeAway: "Other time away",
  annualLeaveBalance: "Annual leave balance",
  annualLeaveBalanceFullYear: "Annual leave balance full year",
};

const days: (CheckboxOptionType & { id: number; fullName: string })[] = [
  { id: 1, label: "Mon", value: WeekDayEnum.Monday, fullName: "Monday" },
  { id: 2, label: "Tue", value: WeekDayEnum.Tuesday, fullName: "Tuesday" },
  { id: 3, label: "Wed", value: WeekDayEnum.Wednesday, fullName: "Wednesday" },
  { id: 4, label: "Thu", value: WeekDayEnum.Thursday, fullName: "Thursday" },
  { id: 5, label: "Fri", value: WeekDayEnum.Friday, fullName: "Friday" },
  { id: 6, label: "Sat", value: WeekDayEnum.Saturday, fullName: "Saturday" },
  { id: 7, label: "Sun", value: WeekDayEnum.Sunday, fullName: "Sunday" },
];

const messages: NotificationMsg = {
  birthdaysThisWeek: ({ date, name }: OtherNotificationMsgArg): string =>
    `Birthday ${name ? `of ${name} is ` : ""}on ${dayjs(date)
      .set("year", dayjs().year())
      .format(DATE_FORMAT.fullDateSecondary)}`,
  probationaryPeriodEndingThisWeek: ({ date, name, probationaryPeriod }: OtherNotificationMsgArg) =>
    `Probationary Period ending ${name ? `of ${name} is ` : ""}on ${dayjs(date)
      .add(probationaryPeriod ?? 0, "month")
      .format(DATE_FORMAT.fullDateSecondary)}`,
  startingThisWeek: ({ date, name }: OtherNotificationMsgArg) =>
    `Starting ${name ? `of ${name} is ` : ""}on ${dayjs(date).format(DATE_FORMAT.fullDateSecondary)}`,
  workAnniversaryThisWeek: ({ date, name }: OtherNotificationMsgArg) =>
    `Work anniversary ${name ? `of ${name} is ` : ""}on ${dayjs(date)
      .set("year", dayjs().year())
      .format(DATE_FORMAT.fullDateSecondary)}`,
  finishingIn: ({ date, name }: OtherNotificationMsgArg) =>
    `Finishing ${name ? `of ${name} is ` : ""}on ${dayjs(date)
      .set("year", dayjs().year())
      .format(DATE_FORMAT.fullDateSecondary)}`,
  leaveApproved: ({ fromDate, toDate, name }: LeaveNotificationMsgArg) =>
    `Leave request approved from ${dayjs(fromDate).format(DATE_FORMAT.fullDate)} to ${dayjs(toDate).format(
      DATE_FORMAT.fullDate,
    )} by ${name}`,
  leaveCreated: ({ fromDate, toDate, name }: LeaveNotificationMsgArg) =>
    `Leave request created from ${dayjs(fromDate).format(DATE_FORMAT.fullDate)} to ${dayjs(toDate).format(
      DATE_FORMAT.fullDate,
    )} by ${name}`,
  leaveDeclined: ({ fromDate, toDate, name }: LeaveNotificationMsgArg) =>
    `Leave request declined from ${dayjs(fromDate).format(DATE_FORMAT.fullDate)} to ${dayjs(toDate).format(
      DATE_FORMAT.fullDate,
    )} by ${name}`,
};

const importEmployeeNotifyPoints: ImportEmployeeNotifyPoints[] = [
  {
    allow: true,
    point: "Employee information can be uploaded in Excel format",
  },
  {
    allow: true,
    point: "Our below downloadable templates will guide you in the format to use",
  },
  {
    allow: true,
    point: "You do not need to complete all fields, only what you wish to upload",
  },
  {
    allow: false,
    point: "Blank entries or deleted columns will not be uploaded",
  },
  {
    allow: false,
    point: "Column names not exactly matching template names will not be uploaded",
  },
  {
    allow: false,
    point: "Information uploaded in incorrect format will not be uploaded",
  },
];

const complianceIssueMessage = {
  holidaysPerAnnum:
    "This is below statutory leave which states an employee is entitled to 4 working weeks in a leave year in which the employee works at least 1,365 hours.",
  hoursPerWeek: "The maximum number of hours that an employee should work in an average working week is 48 hours.",
  break: {
    max: "An employee is entitled to a 30-minute break where more than 6 hours have been worked.",
    min: "An employee is entitled to a 15-minute break where more than 4.5 hours have been worked.",
  },
};

const employeeGenderLabel = {
  male: "Male",
  female: "Female",
  not_disclosed: "Other / Not disclosed",
};

export const EMPLOYEE = {
  databaseFields,
  employeePersonalDetails,
  employment,
  compensation,
  leave,
  days,
  messages,
  importEmployeeNotifyPoints,
  complianceIssueMessage,
  employeeGenderLabel,
};

export default EMPLOYEE;
