import { List, Modal, Space, Typography } from "antd";
import { BaseButton, DefaultButton } from "components/Buttons";
import { Container } from "components/core";
import COLORS from "constants/colors";
import styled from "styled-components";
import { useModal } from "components/modal";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { Employee, FetchEmployeeResponse, FetchUserDataArg } from "model/Employee";
import { useCallback, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FETCH_USER } from "services/graphql/employee";
import TABLE from "constants/table";
import { NotLoggedInData, NotLoggedInUserDetails } from "model/Insights";
import useLoginReminder from "hooks/useLoginReminder";
import notLoggedInBg from "assets/images/not-logged-in.svg";
import Icon from "components/Icon";
import NotLoggedInSvg from "./NotLoggedInSvg";

const NotLoggedIn = () => {
  const { isModalVisible, hideModal, showModal } = useModal();
  const [data, setData] = useState<NotLoggedInData>();
  const [loadingState, setLoadingState] = useState<{ [x: string]: boolean }>({});
  const [refetch, { loading: refetchLoading }] = useLazyQuery<FetchEmployeeResponse>(FETCH_USER);

  const fetchUserData = useCallback(
    async ({ cursor, accumulated }: FetchUserDataArg) => {
      const result = await refetch({
        variables: {
          first: TABLE.rowsPerPage,
          after: cursor || undefined,
          fetchBirthdaysThisWeek: false,
          fetchWorkAnniversaryThisWeek: false,
        },
      });
      if (result.data) {
        const { nodes, pageInfo } = result?.data?.users;
        const newData = [...(accumulated ?? []), ...nodes];
        if (pageInfo?.hasNextPage) {
          return fetchUserData({ accumulated: newData, cursor: pageInfo.endCursor });
        }
        return newData;
      }
      return [];
    },
    [refetch],
  );

  const modifyData = (userData: Employee[]) => {
    const { loggedInCount, notLoggedInCount, userDetails } = userData.reduce(
      (acc, user) => {
        if (user.loggedIn) {
          acc.loggedInCount += 1;
        } else {
          acc.notLoggedInCount += 1;
          acc.userDetails.push({ id: user.id, name: user.name, profileImageUrl: user.profileImageUrl });
        }
        return acc;
      },
      { loggedInCount: 0, notLoggedInCount: 0, userDetails: [] as NotLoggedInUserDetails[] },
    );
    const totalCount = userData.length;
    const loggedInRatio = totalCount ? parseFloat(((loggedInCount / totalCount) * 100).toFixed(2)) : 0;
    const notLoggedInRatio = totalCount ? parseFloat(((notLoggedInCount / totalCount) * 100).toFixed(2)) : 0;
    setData({
      notLoggedInCount,
      loggedInRatio,
      notLoggedInRatio,
      userDetails,
      loggedInCount,
    });
  };

  const { loading } = useQuery<FetchEmployeeResponse>(FETCH_USER, {
    variables: {
      first: TABLE.rowsPerPage,
      fetchBirthdaysThisWeek: false,
      fetchWorkAnniversaryThisWeek: false,
    },
    onCompleted: (response) => {
      const { nodes, pageInfo } = response?.users;

      if (pageInfo?.hasNextPage) {
        fetchUserData({ accumulated: [...nodes], cursor: pageInfo.endCursor }).then((data) => modifyData(data));
      } else {
        modifyData([...nodes]);
      }
    },
  });

  const [reminderMutate] = useLoginReminder();

  const sendReminderHandler = async (id: string) => {
    setLoadingState((prev) => ({ ...prev, [id]: true }));
    reminderMutate({ variables: { userId: id } }).then(() => {
      setLoadingState((prev) => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });
    });
  };

  return (
    <StyledContainer data-testid="notLoggedIn">
      <StyledTitleWrapper>
        <StyledTitle level={2}>Employees login status</StyledTitle>
      </StyledTitleWrapper>
      <div className="d-flex justify-content-center align-items-center flex-column pt-1">
        <StyledSvgContainer>
          <NotLoggedInSvg notLoggedInUsers={data?.loggedInRatio ?? 0} loading={loading || refetchLoading} />
        </StyledSvgContainer>
        <StyledDescriptionContainer />
        <StyledLegendData>
          <StyledLegend className="loggedIn">
            <Typography.Title className="ratio" level={2}>
              {data?.loggedInRatio ?? 0}%
            </Typography.Title>
            <Typography.Text className="count">{data?.loggedInCount ?? 0} employees</Typography.Text>
            <Typography.Text>Logged in</Typography.Text>
          </StyledLegend>
          <StyledLegend className="notLoggedIn">
            <Typography.Title className="ratio" level={2}>
              {data?.notLoggedInRatio ?? 0}%
            </Typography.Title>
            <Typography.Text className="count">{data?.notLoggedInCount ?? 0} employees</Typography.Text>
            <Typography.Text>Not logged in</Typography.Text>
          </StyledLegend>
        </StyledLegendData>
        <StyledNotification>
          <div className={`d-flex ${data?.notLoggedInCount ? "justify-content-end" : "justify-content-start"}`}>
            {data?.notLoggedInCount !== 0 ? (
              <BaseButton onClick={showModal} disabled={!data} className="me-2">
                View
              </BaseButton>
            ) : (
              <div className="d-flex gap-2 mb-lg-1 mb-xl-2">
                <StyledIcon name="successCheck" />
                <StyledTitle level={2}>All employees are logged in</StyledTitle>
              </div>
            )}
          </div>
        </StyledNotification>
      </div>
      <StyledModal
        open={isModalVisible}
        onCancel={hideModal}
        footer={null}
        width={428}
        centered
        destroyOnClose
        rootClassName="secondary"
        title={<StyledModalTitle level={4}>Employees not yet logged in</StyledModalTitle>}
      >
        <List
          bordered
          dataSource={data?.userDetails ?? []}
          renderItem={(item) => (
            <StyledListItem>
              <Space size={[15, 0]}>
                <UserAvatar section="header" />
                <Typography.Text>{item.name}</Typography.Text>
              </Space>
              <DefaultButton
                loading={loadingState[item.id] || false}
                onClick={() => {
                  sendReminderHandler(item.id);
                }}
              >
                Send reminder
              </DefaultButton>
            </StyledListItem>
          )}
        />
      </StyledModal>
    </StyledContainer>
  );
};

export default NotLoggedIn;

const StyledContainer = styled(Container)`
  height: 100%;
  padding: 8px 12px 18px 12px;
  background: linear-gradient(180deg, ${COLORS.white} 38.96%, ${COLORS.linearBgColor} 100%);
  box-shadow: 1px 1px 0px 0px ${COLORS.lightBoxShadowColor};
  position: relative;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 9px 20px 9px;
  border-bottom: 1px solid ${COLORS.borderLight};
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
    color: ${COLORS.inputFieldTextColor};
    margin: 0px;
  }
`;

const StyledSubTitle = styled(Typography.Text)`
  &.ant-typography {
    font-size: 13px;
    font-weight: 500;
    color: ${COLORS.inputFieldTextColor};
  }
`;

const StyledLegendData = styled.div`
  position: absolute;
  z-index: 1;
  left: 28px;
  right: 28px;
  bottom: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLegend = styled.div`
  font-size: 12px;
  color: ${COLORS.inputFieldTextColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  &:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 3px;
    top: 1px;
    height: 55px;
    border-radius: 4px;
    display: flex;
    z-index: 1;
  }
  &.notLoggedIn {
    align-items: flex-end;
    & .ant-typography {
      text-align: end;
    }
  }
  &.notLoggedIn:before {
    background: linear-gradient(
      180.44deg,
      ${COLORS.notLoggedInStartGradientColor} 0.77%,
      ${COLORS.notLoggedInEndGradientColor} 99.62%
    );
    right: -9px;
  }
  &.loggedIn {
    align-items: flex-start;
    & .ant-typography {
      text-align: start;
    }
  }
  &.loggedIn:before {
    background: linear-gradient(
      167.17deg,
      ${COLORS.loggedInStartGradientColor} 10.72%,
      ${COLORS.loggedInEndGradientColor} 90.72%
    );
    left: -9px;
  }
  & .ant-typography {
    color: ${COLORS.headerText};
    font-size: 12px;
  }
  & .ant-typography.count {
    color: ${COLORS.primaryColor};
  }
  & .ant-typography.ratio {
    margin: 0px;
    font-size: 15px;
    color: ${COLORS.inputFieldTextColor};
  }
`;

const StyledListItem = styled(List.Item)`
  &.ant-list-item {
    padding: 6px 8px 6px 17px;
  }
`;

const StyledModalTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${COLORS.inputFieldTextColor};
    margin: 0;
    padding-top: 7px;
  }
`;

const StyledModal = styled(Modal)`
  .ant-list ul.ant-list-items {
    max-height: 475px;
    overflow: auto;
  }
`;

const StyledSvgContainer = styled.div`
  height: 190px;
  position: relative;
  z-index: 1;
`;

const StyledDescriptionContainer = styled.div`
  background-image: url(${notLoggedInBg});
  position: absolute;
  bottom: 0;
  background-repeat: no-repeat;
  left: 0;
  height: 35%;
  z-index: 0;
  width: 100%;
  background-size: cover;
`;

const StyledIcon = styled(Icon)`
  & svg circle {
    fill: ${COLORS.successCheckColor};
  }
`;

const StyledNotification = styled.div`
  position: absolute;
  bottom: 12px;
  width: 89%;
`;
