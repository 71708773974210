import { useMutation } from "@apollo/client";
import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import { DefaultButton, LinkButton } from "components/Buttons";
import Icon from "components/Icon";
import { tableColumnCommonData, sortingTableData, SortTypeEnum, SortOrderEnum } from "components/Table";
import { ConfirmModal } from "components/modal";
import DATE_FORMAT from "constants/dateFormat";
import TABLE from "constants/table";
import { useFetchRequestList } from "contexts/LeaveRequestListProvider";
import dayjs from "dayjs";
import { getFilenameFromUrl, removeExtensionFromName } from "features/employee/leave";
import { useWindowInfiniteScroll } from "hooks";
import useFormModal from "hooks/useFormModal";
import { PageInfo } from "model/Common";
import {
  DocumentRefetch,
  DocumentsAssociatedWithLeave,
  DestroyDocumentAttachedWithLeaveResponse,
} from "model/Document";
import { EmployeeId } from "model/Employee";
import { BookingStatus } from "model/Leave";
import { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import employee from "routes/employee";
import { useNotify } from "services/api";
import { DESTROY_DOCUMENT_ATTACHED_WITH_LEAVE } from "services/graphql/document";
import styled from "styled-components";
import { downloadLink } from "utils";

const LeaveDocumentsListTable = forwardRef<DocumentRefetch>((_, ref) => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DocumentsAssociatedWithLeave>>({});
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [deleteId, setDeleteId] = useState<string>();
  const notify = useNotify();
  const {
    isModalVisible: isDestroyConfirmModalVisible,
    hideModal: hideDestroyConfirmModal,
    showModal: showDestroyConfirmModal,
  } = useFormModal();

  const {
    documentsAssociatedWithLeaveLoading,
    refetchDocumentAssociatedWithLeave,
    documentAssociatedWithLeave,
    isRefetchAssociatedWithLeave,
    setIsRefetchAssociatedWithLeave,
  } = useFetchRequestList();

  const refetchData = useCallback(
    async (cursor: string) => {
      const refetchPageInfo = await refetchDocumentAssociatedWithLeave({ cursor });
      setPageInfo(refetchPageInfo);
    },
    [refetchDocumentAssociatedWithLeave],
  );

  const fetchData = useCallback(async () => {
    const pageInfo = await refetchDocumentAssociatedWithLeave({});
    if (pageInfo?.hasNextPage && pageInfo?.endCursor) {
      refetchData(pageInfo?.endCursor);
    }
  }, [refetchData, refetchDocumentAssociatedWithLeave]);

  useLayoutEffect(() => {
    if (isRefetchAssociatedWithLeave) {
      fetchData();
      setIsRefetchAssociatedWithLeave(false);
    }
  }, [fetchData, isRefetchAssociatedWithLeave, setIsRefetchAssociatedWithLeave]);

  useLayoutEffect(() => {
    fetchData();
    setIsRefetchAssociatedWithLeave(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [removeDocument] = useMutation<DestroyDocumentAttachedWithLeaveResponse>(DESTROY_DOCUMENT_ATTACHED_WITH_LEAVE, {
    onCompleted: (response) => {
      if (response?.updateLeaveRequest) {
        if (response.updateLeaveRequest.errors) {
          notify.error(response.updateLeaveRequest.errors.fullMessages);
        } else {
          notify.success({ message: "Document deleted." });
          hideDestroyConfirmModal();
          setPageInfo(undefined);
          fetchData();
        }
      }
    },
  });

  useImperativeHandle(ref, () => ({
    documentRefetch() {
      setPageInfo(undefined);
      fetchData();
    },
  }));

  useWindowInfiniteScroll({
    hasNextPage: pageInfo?.hasNextPage,
    endCursor: pageInfo?.endCursor,
    loading: documentsAssociatedWithLeaveLoading,
    callback: () => {
      if (pageInfo?.endCursor) refetchData(pageInfo.endCursor);
    },
  });

  const columns: ColumnsType<DocumentsAssociatedWithLeave> = [
    {
      ...tableColumnCommonData({
        title: "Document name",
        dataIndex: "attachmentName",
        defaultSortOrder: SortOrderEnum.Descend,
        width: "34%",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { attachmentName }) => (
        <div className="d-flex justify-content-start align-items-center gap-2">
          <Icon name="document" />
          <Typography.Title level={2} className="mb-0 title">
            {removeExtensionFromName(attachmentName)}
          </Typography.Title>
        </div>
      ),
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.String, firstData: a!.attachmentName, secondData: b!.attachmentName }),
    },
    {
      ...tableColumnCommonData({
        title: "Shared with",
        dataIndex: "sharedWithCount",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { id, user }) => (
        <Link to={employee.view(user.id as EmployeeId)} tabIndex={-1}>
          <LinkButton className="p-0">{user.name}</LinkButton>
        </Link>
      ),
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.String, firstData: a.user.name, secondData: b.user.name }),
    },
    {
      ...tableColumnCommonData({
        title: "Date added",
        dataIndex: "dateAdded",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { dateAdded }) => (dateAdded ? dayjs(dateAdded).format(DATE_FORMAT.fullDateDefault) : "N/A"),
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.dateAdded, secondData: b.dateAdded }),
    },
    {
      title: "",
      dataIndex: "download",
      render: (_, { attachmentUrl }) => (
        <DefaultButton
          onClick={(event) => {
            event.stopPropagation();
            downloadLink(attachmentUrl, getFilenameFromUrl(attachmentUrl));
          }}
        >
          Download
        </DefaultButton>
      ),
      width: 100,
    },
    {
      title: "",
      dataIndex: "delete",
      render: (_, { id, bookingStatus }) =>
        bookingStatus !== BookingStatus.Taken && (
          <DefaultButton
            className="me-1"
            onClick={(event) => {
              event.stopPropagation();
              showDestroyConfirmModal();
              setDeleteId(id);
            }}
          >
            Delete
          </DefaultButton>
        ),
      width: 80,
    },
  ];

  return (
    <StyledWrapper>
      <Table
        columns={columns}
        dataSource={documentAssociatedWithLeave ?? []}
        pagination={false}
        loading={documentsAssociatedWithLeaveLoading}
        sticky
        rowKey="id"
        sortDirections={TABLE.tableSortDirections}
        onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<DocumentsAssociatedWithLeave>)}
      />
      <ConfirmModal
        onCancel={hideDestroyConfirmModal}
        open={isDestroyConfirmModalVisible}
        onOk={() => removeDocument({ variables: { id: deleteId, attachment: null } })}
        width={310}
        title="Are you sure you want to delete this document?"
        okText="Yes"
        cancelText="No"
      />
    </StyledWrapper>
  );
});

export default LeaveDocumentsListTable;

const StyledWrapper = styled.div`
  .ant-table-column-sorter {
    display: none;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
  }

  .linkButton {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .ant-table-tbody tr:hover .ant-btn-link {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;
