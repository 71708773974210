import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import Icon from "components/Icon";
import COLORS from "constants/colors";
import EMPLOYEE from "constants/employee";
import { BreakdownFilterEnum, EmployeeBreakDownTableData } from "model/Insights";
import styled from "styled-components";

type Props = {
  data: EmployeeBreakDownTableData[];
  filterBy: BreakdownFilterEnum;
  colors: string[];
  loading: boolean;
  activeLabelIndex?: number;
  onMouseHoverHandler: (index: number) => void;
  onMouseLeaveHandler: () => void;
};

const EmployeeBreakdownTable = ({
  data,
  filterBy,
  colors,
  loading,
  activeLabelIndex,
  onMouseHoverHandler,
  onMouseLeaveHandler,
}: Props) => {
  const columns: ColumnsType<EmployeeBreakDownTableData> = [
    {
      title:
        filterBy === BreakdownFilterEnum.Department
          ? "Department"
          : filterBy === BreakdownFilterEnum.Location
          ? "Location"
          : "Gender",
      dataIndex: "name",
      align: "left",
      width: "40%",
      render: (_, { name }, index) => (
        <div className="d-flex">
          <StyledLegend color={colors[index]} />
          <StyledName>
            {Object.keys(EMPLOYEE.employeeGenderLabel).includes(name)
              ? EMPLOYEE.employeeGenderLabel[name as keyof typeof EMPLOYEE.employeeGenderLabel]
              : name}
          </StyledName>
        </div>
      ),
    },
    {
      title: "Headcount",
      dataIndex: "headCount",
      align: "right",
      width: 78,
      render: (_, { headCount }) => <StyledCurrentYearData>{headCount}</StyledCurrentYearData>,
    },
    {
      title: "%",
      dataIndex: "headCount",
      align: "right",
      width: 50,
      render: (_, { currentYear }) => <StyledCurrentYearData>{currentYear}%</StyledCurrentYearData>,
    },
    {
      title: "Prev. year",
      dataIndex: "previousYear",
      align: "right",
      width: 84,
      render: (_, { previousYear }) => (
        <StyledPreviousYear className={previousYear > 0 ? "increase" : "decrease"}>
          <StyledIcon name="caretUpOutlined" />
          {Math.abs(previousYear)}%
        </StyledPreviousYear>
      ),
    },
  ];

  return (
    <StyledTableWrapper>
      <Table
        id="employeeBreakdown"
        rowKey="id"
        columns={columns}
        size="large"
        dataSource={data}
        pagination={false}
        bordered={false}
        loading={loading}
        scroll={{
          scrollToFirstRowOnChange: false,
          y: 235,
        }}
        rowClassName={(_, index) => (index === activeLabelIndex ? "highlight-row" : "")}
        onRow={(_, index) => ({
          onMouseEnter: () => {
            onMouseHoverHandler(index ?? 0);
          },
          onMouseLeave: onMouseLeaveHandler,
        })}
      />
    </StyledTableWrapper>
  );
};

export default EmployeeBreakdownTable;

const StyledName = styled(Typography.Text)`
  color: ${COLORS.inputFieldTextColor};
  font-size: 13px;
  padding-left: 6px;
`;

const StyledLegend = styled.div<{ color: string }>`
  font-size: 12px;
  color: ${COLORS.inputFieldTextColor};
  display: flex;
  align-items: center;
  &:before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 1px;
    margin-right: 6px;
    background: ${({ color }) => color};
  }
`;

const StyledTableWrapper = styled.div`
  .ant-table .ant-table-tbody tr td:last-child,
  .ant-table .ant-table-thead tr th:nth-child(4) {
    padding-right: 16px;
  }

  & .ant-table .ant-table-header {
    border-radius: 0px;
    position: relative;
  }

  & .ant-table .ant-table-thead tr th,
  & .ant-table .ant-table-thead tr td,
  & .ant-table .ant-table-tbody tr td {
    border: none;
    font-size: 13px;
  }

  & .ant-table .ant-table-thead tr th:first-child,
  & .ant-table .ant-table-thead tr td:first-child,
  & .ant-table .ant-table-tbody tr td:first-child {
    padding-left: 8px;
  }

  & .ant-table .ant-table-body {
    margin-top: 11px;
    padding-bottom: 10px;
  }

  & .ant-table .ant-table-header::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 11px);
    border-top: 1px solid ${COLORS.borderLight};
    z-index: 1;
  }

  & .ant-table .ant-table-header::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 11px);
    border-bottom: 1px solid ${COLORS.borderLight};
    z-index: 1;
  }

  & .ant-table .ant-table-tbody tr td {
    font-size: 13px;
    height: 40px;
  }

  & .ant-table .ant-table-tbody tr td:last-child {
    font-weight: 700;
  }

  & .ant-table .ant-table-tbody tr:first-child td {
    padding-top: 9px;
  }

  & .ant-table .highlight-row {
    background-color: ${COLORS.darkRowBgHover};

    td:first-child .ant-typography {
      font-weight: 500;
    }
  }

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none;
  }
`;

const StyledPreviousYear = styled(Typography.Title)`
  &.ant-typography {
    font-size: 13px;
    margin: 0px;
  }
  &.ant-typography.increase {
    color: ${COLORS.successDarkColor};
  }
  &.ant-typography.decrease {
    color: ${COLORS.errorLightColor};
  }

  &.ant-typography.decrease .anticon {
    transform: rotate(90deg);
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 10px;
`;

const StyledCurrentYearData = styled(Typography.Text)`
  font-size: 13px;
  color: ${COLORS.headerTextBold};
`;
