import { Input, InputProps, InputRef, Typography } from "antd";
import { DefaultButton } from "components/Buttons";
import Icon from "components/Icon";
import COLORS from "constants/colors";
import { ReactElement, useRef } from "react";
import styled from "styled-components";

interface Props extends Omit<InputProps, "type"> {
  initialValue?: string | null;
  onRemove?: () => void;
}

export const InputFile = ({ initialValue, onRemove, ...props }: Props): ReactElement => {
  const ref = useRef<InputRef>(null);

  return (
    <StyledWrapper className="position-relative">
      <StyledDefaultButton className="position-absolute btn" onClick={() => ref.current?.input?.click()} tabIndex={-1}>
        Browse
      </StyledDefaultButton>
      <StyledInput type="file" {...props} ref={ref} />
      {initialValue && (
        <StyledInputTextBox
          className="input-text"
          onClick={(e) => {
            e.stopPropagation();
            ref.current?.input?.click();
          }}
        >
          <StyledText>{initialValue}</StyledText>
          {onRemove && (
            <StyledRemoveIcon
              name="remove"
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
                onRemove();
              }}
            />
          )}
        </StyledInputTextBox>
      )}
    </StyledWrapper>
  );
};

export default InputFile;

const StyledWrapper = styled.div`
  position: relative;
  input[type="file"]::file-selector-button {
    display: none;
  }

  &:hover .anticon {
    display: flex;
  }

  &:hover .input-text + input,
  &:hover .btn + input {
    border-color: ${COLORS.inputBorderHover};
  }
`;

const StyledDefaultButton = styled(DefaultButton)`
  top: 1px;
  right: 1px;
  height: 34px;
  z-index: 1;
}
`;

const StyledInput = styled(Input)`
  padding: 6px 11px;
  height: 36px;
  font-weight: 500;
`;

const StyledInputTextBox = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 78px;
  background: white;
  border-radius: 4px;
  z-index: 1;
  padding: 6px 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const StyledText = styled(Typography.Text)`
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledRemoveIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  padding: 4px;
  display: none;
  cursor: pointer;
  background: ${COLORS.colorTextDisabled};
  border-radius: 8px;
  & path {
    fill: ${COLORS.white};
  }
  &:hover {
    background: ${COLORS.colorTextDisabledSecondary};
  }
`;
