export default () => (
  <svg width="56" height="53" viewBox="0 0 56 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.706 18.6h10.588a.67.67 0 0 0 .5-.234A.86.86 0 0 0 23 17.8a.86.86 0 0 0-.207-.566.67.67 0 0 0-.499-.234H11.706a.67.67 0 0 0-.5.234.86.86 0 0 0-.206.566c0 .212.074.416.207.566.132.15.312.234.499.234M34 24.8a.9.9 0 0 0-.055-.306.8.8 0 0 0-.156-.26.7.7 0 0 0-.233-.173.7.7 0 0 0-.275-.061H11.72a.68.68 0 0 0-.508.234.85.85 0 0 0-.211.566c0 .212.076.416.21.566.135.15.318.234.509.234h21.56q.144 0 .275-.06.133-.062.233-.174a.8.8 0 0 0 .156-.26A.9.9 0 0 0 34 24.8m-22.177 7.8h15.354a.84.84 0 0 0 .582-.234A.8.8 0 0 0 28 31.8a.8.8 0 0 0-.241-.566.84.84 0 0 0-.582-.234H11.823a.84.84 0 0 0-.582.234.8.8 0 0 0-.241.566c0 .212.087.416.241.566.155.15.364.234.582.234m-.045 7h14.444a.77.77 0 0 0 .55-.234.81.81 0 0 0 0-1.132.77.77 0 0 0-.55-.234H11.778a.77.77 0 0 0-.55.234.81.81 0 0 0 0 1.132c.146.15.344.234.55.234"
      fill="#3A4C61"
    />
    <path
      d="M32.075 47.393h-21.7a2.75 2.75 0 0 1-1.855-.707 2.32 2.32 0 0 1-.77-1.704V8.018c0-.64.278-1.252.77-1.704a2.75 2.75 0 0 1 1.855-.707H27c.464.001.909.17 1.236.472.328.301.513.71.514 1.135v4.018c.001 1.065.463 2.087 1.283 2.84s1.932 1.177 3.092 1.178H37.5c.464 0 .908.17 1.236.472.328.3.513.71.514 1.135v7.031c0 .213.092.418.256.569s.387.235.619.235a.92.92 0 0 0 .619-.235.77.77 0 0 0 .256-.569v-7.03a3 3 0 0 0-.265-1.231 3.2 3.2 0 0 0-.761-1.042l-10.5-9.643a3.5 3.5 0 0 0-1.134-.698A3.8 3.8 0 0 0 27 4H10.375c-1.16.001-2.272.425-3.092 1.178S6 6.953 6 8.018v36.964c.001 1.065.463 2.087 1.283 2.84S9.215 48.999 10.375 49h21.7a.92.92 0 0 0 .618-.235.77.77 0 0 0 .257-.569.77.77 0 0 0-.257-.568.92.92 0 0 0-.618-.235M30.5 8.158l5.973 5.485h-3.348a2.75 2.75 0 0 1-1.855-.707 2.32 2.32 0 0 1-.77-1.704z"
      fill="#3A4C61"
    />
    <path
      d="M51.506 30.366a.75.75 0 0 1 .14.427l.087 7.85c-.01 5.164-4.731 8.133-9.367 9.357-4.63-1.22-9.356-4.195-9.366-9.348l.087-7.86a.75.75 0 0 1 .497-.697l8.53-3.051a.75.75 0 0 1 .505 0l8.529 3.051a.75.75 0 0 1 .358.271"
      fill="#84E297"
    />
    <path
      d="m45.248 34.694-4.02 4.02-1.744-1.745a.75.75 0 0 0-1.06 1.06l2.275 2.276a.75.75 0 0 0 1.06 0l4.55-4.55a.749.749 0 1 0-1.06-1.06"
      fill="#fff"
    />
  </svg>
);
