import { Modal, Typography } from "antd";
import { BaseButton, DefaultButton } from "components/Buttons";
import { Container } from "components/core";
import styled from "styled-components";
import { useModal } from "components/modal";
import COLORS from "constants/colors";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FETCH_USERS_COMPLIANCE_ISSUES_AND_BURNOUT_RADAR } from "services/graphql/insights";
import TABLE from "constants/table";
import { useCallback, useState } from "react";
import {
  BurnoutRadarData,
  FetchBurnoutRadarArg,
  UsersComplianceIssueAndBurnoutRadar,
  FetchBurnoutRadarResponse,
} from "model/Insights";
import { includesInArray } from "utils";
import { isManager } from "model/User";
import { useAuthContext } from "contexts";
import BurnoutRadarTable from "./BurnoutRadarTable";

const BurnoutRadar = () => {
  const { isModalVisible, hideModal, showModal } = useModal();
  const [data, setData] = useState<BurnoutRadarData[]>([]);
  const { user } = useAuthContext();

  const [refetch, { loading: refetchLoading }] = useLazyQuery<FetchBurnoutRadarResponse>(
    FETCH_USERS_COMPLIANCE_ISSUES_AND_BURNOUT_RADAR,
  );

  const fetchBurnoutRadarData = useCallback(
    async ({ cursor, accumulated }: FetchBurnoutRadarArg) => {
      const result = await refetch({
        variables: {
          first: TABLE.rowsPerPage,
          after: cursor || undefined,
        },
      });
      if (result.data) {
        const { nodes, pageInfo } = result?.data?.fetchUsersComplianceIssueAndBurnoutRadar;
        const newData = [...(accumulated ?? []), ...nodes];
        if (pageInfo?.hasNextPage) {
          return fetchBurnoutRadarData({ accumulated: newData, cursor: pageInfo.endCursor });
        }
        return newData;
      }
      return [];
    },
    [refetch],
  );
  const roundOfNumber = (value: number) => {
    if (!value) return "0";
    const rounded = Math.round(value * 100) / 100;
    const result = rounded % 1 > 0 ? rounded.toFixed(2) : rounded.toString();
    return result;
  };
  const modifyData = (userData: UsersComplianceIssueAndBurnoutRadar[]) => {
    const modifiedDataResult = userData.reduce((acc, user) => {
      const modifiedData = {
        id: user.id,
        name: user.name,
        profileImageUrl: user.profileImageUrl,
        taken: roundOfNumber(user.holidayBalance?.takenDays),
        booked: roundOfNumber(user.holidayBalance?.bookedDays),
        accrued: roundOfNumber(user.holidayBalance?.accruedBalance),
        remaining: roundOfNumber((user.holidayBalance?.accruedBalance ?? 0) - (user.holidayBalance?.takenDays ?? 0)),
      };

      acc.push(modifiedData);
      return acc;
    }, [] as BurnoutRadarData[]);

    const filterData = isManager(user)
      ? modifiedDataResult.filter((item) => includesInArray(user?.managedUserIds, item.id))
      : modifiedDataResult;

    const sortData = filterData.sort((a, b) => parseFloat(b.remaining) - parseFloat(a.remaining));
    setData(sortData);
  };

  const { loading } = useQuery<FetchBurnoutRadarResponse>(FETCH_USERS_COMPLIANCE_ISSUES_AND_BURNOUT_RADAR, {
    variables: {
      first: TABLE.rowsPerPage,
    },
    onCompleted: (response) => {
      const { nodes, pageInfo } = response?.fetchUsersComplianceIssueAndBurnoutRadar;

      if (pageInfo?.hasNextPage) {
        fetchBurnoutRadarData({ accumulated: [...nodes], cursor: pageInfo.endCursor }).then((data) => modifyData(data));
      } else {
        modifyData([...nodes]);
      }
    },
  });

  const downloadCSV = useCallback(() => {
    const csvContent = [
      TABLE.burnoutRadarTableHeaders,
      ...data.map((item) => {
        const { id, profileImageUrl, ...downloadableData } = item;
        return Object.values(downloadableData);
      }),
    ];
    const csvString = csvContent.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "burnout_radar_list.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [data]);

  return (
    <StyledContainer data-testid="burnoutRadar">
      <div className="d-flex justify-content-between ">
        <StyledHeaderTitle level={4}>Rest & Recharge Tracker</StyledHeaderTitle>
        <StyledShowAllButton onClick={showModal}>Show all</StyledShowAllButton>
      </div>
      <StyledTableWrapper>
        <BurnoutRadarTable data={data.slice(0, 5)} loading={loading || refetchLoading} />
      </StyledTableWrapper>

      <Modal
        open={isModalVisible}
        onCancel={hideModal}
        footer={null}
        width={850}
        centered
        destroyOnClose
        rootClassName="secondary"
        title={
          <div className="d-flex justify-content-between align-items-center">
            <StyledModalTitle level={4}>Rest & Recharge Tracker</StyledModalTitle>
            <StyledDefaultButton onClick={downloadCSV}>Download this list to Excel</StyledDefaultButton>
          </div>
        }
      >
        <StyledModalTableWrapper>
          <BurnoutRadarTable data={data} loading={loading || refetchLoading} type="list" />
        </StyledModalTableWrapper>
      </Modal>
    </StyledContainer>
  );
};

export default BurnoutRadar;

const StyledContainer = styled(Container)`
  padding: 0px;
  height: 100%;
  box-shadow: 1px 1px 0px 0px ${COLORS.lightBoxShadowColor};
`;
const StyledHeaderTitle = styled(Typography.Title)`
  &.ant-typography {
    padding: 21px 0px 20px 18px;
    margin: 0px;
  }
`;

const StyledShowAllButton = styled(BaseButton)`
  margin: 11px 8px;
`;

const StyledTableWrapper = styled.div`
  padding: 0px 19px 16px 21px;

  & .ant-table .ant-table-tbody tr td:first-child,
  & .ant-table .ant-table-thead tr th:first-child {
    padding-left: 0px;
  }
  & .ant-table .ant-table-content {
    border-top: 1px solid ${COLORS.borderLight};
  }

  & .ant-table .ant-table-tbody tr:first-child td {
    padding-top: 9px;
  }

  & .ant-table .ant-table-tbody tr td {
    border: none;
  }
`;

const StyledModalTableWrapper = styled.div`
  border: 1px solid ${COLORS.defaultColor};
  border-radius: 4px;
  overflow: hidden;
`;

const StyledModalTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 15px;
    margin: 0px;
  }
`;

const StyledDefaultButton = styled(DefaultButton)`
  margin-right: 26px;
`;
