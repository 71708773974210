import { Dayjs } from "dayjs";
import { EmployeeId } from "./Employee";
import { PageInfo, WorkingCondition } from "./Common";
import { LeaveRequestsReason } from "./Leave";

export interface BasicEmployeeDetails {
  id: EmployeeId;
  name: string;
  profileImageUrl?: string | null;
}

export interface NotLoggedInData {
  notLoggedInCount: number;
  loggedInRatio: number;
  notLoggedInRatio: number;
  userDetails: NotLoggedInUserDetails[];
  loggedInCount: number;
}

export interface NotLoggedInUserDetails extends BasicEmployeeDetails {}

export interface LabelFilter {
  departments?: string;
  locations?: string;
}

export interface DateRangePicker {
  from: Dayjs;
  to: Dayjs;
}

export interface UserCountStats {
  monthYear: string;
  totalUsers: number;
  usersLeftInMonth: number;
  usersStartedInMonth: number;
}

export interface PastMonthCount extends Omit<UserCountStats, "totalUsers"> {}

export interface PastYearCount extends Omit<UserCountStats, "usersLeftInMonth" | "usersStartedInMonth"> {}

export interface FetchUserCountStatsResponse {
  employeesCount: UserCountStats[];
  pastMonthCount: PastMonthCount[];
  pastYearCount: PastYearCount[];
}

export type LineChartData = {
  month: string;
  totalEmployees: number;
  joiners: number;
  leavers: number;
};

export interface EmployeeBreakdownResponse {
  id: string;
  name: string;
  headCount: number;
  previousYear: number;
}

export enum BreakdownFilterEnum {
  Department = "DEPARTMENT",
  Location = "LOCATION",
  Gender = "GENDER",
}

export const breakdownFilterLabel: Record<BreakdownFilterEnum, string> = {
  [BreakdownFilterEnum.Department]: "By department",
  [BreakdownFilterEnum.Location]: "By location",
  [BreakdownFilterEnum.Gender]: "By gender",
};

export enum ComplianceIssuesFilterEnum {
  Employee = "EMPLOYEE",
  Issue = "ISSUE",
}

export const complianceIssuesFilterLabel: Record<ComplianceIssuesFilterEnum, string> = {
  [ComplianceIssuesFilterEnum.Employee]: "By employee",
  [ComplianceIssuesFilterEnum.Issue]: "By issue",
};

export enum ComplianceIssueEnum {
  Leave = "LEAVE",
  Hours = "HOURS",
  BREAK = "BREAK",
}

export const complianceIssueLabel: Record<ComplianceIssueEnum, string> = {
  [ComplianceIssueEnum.Leave]: "Leave Below Statutory Requirement",
  [ComplianceIssueEnum.Hours]: "Hours Exceed Statutory Recommendation",
  [ComplianceIssueEnum.BREAK]: "Break Below Statutory Requirement",
};

export interface ComplianceByEmployee extends BasicEmployeeDetails {
  issues: ComplianceIssueEnum[];
}

export interface ComplianceByIssue {
  issue: ComplianceIssueEnum;
  users: BasicEmployeeDetails[];
}

export interface HolidayBalance {
  holidaysPerYear: number;
  accruedBalance: number;
  takenDays: number;
  bookedDays: number;
}
export interface UsersComplianceIssueAndBurnoutRadar extends BasicEmployeeDetails {
  holidayBalance: HolidayBalance;
  workingCondition: WorkingCondition;
}

export interface FetchComplianceIssueResponse {
  fetchUsersComplianceIssueAndBurnoutRadar: {
    nodes: UsersComplianceIssueAndBurnoutRadar[];
    edges: Object;
    pageInfo: PageInfo;
  };
}

export interface ComplianceIssueTableData {
  byEmployee: ComplianceByEmployee[];
  byIssue: ComplianceByIssue[];
}

export interface FetchComplianceIssueUserListArg {
  accumulated?: UsersComplianceIssueAndBurnoutRadar[] | null;
  cursor?: string | null;
}

export interface UsersGroupStats {
  name: string;
  usersCount: number;
}
export interface FetchUsersGroupStatsResponse {
  currentYear: UsersGroupStats[];
  lastYear: UsersGroupStats[];
}

export interface ModifyUserGroupStatsData extends EmployeeBreakdownResponse {
  growth: number;
  currentYear: number;
}

export interface EmployeeBreakDownTableData extends Omit<ModifyUserGroupStatsData, "growth"> {}

export interface FetchBurnoutRadarResponse {
  fetchUsersComplianceIssueAndBurnoutRadar: {
    edges: Object;
    nodes: UsersComplianceIssueAndBurnoutRadar[];
    pageInfo: PageInfo;
  };
}

export interface FetchBurnoutRadarArg {
  accumulated?: UsersComplianceIssueAndBurnoutRadar[] | null;
  cursor?: string | null;
}

export interface BurnoutRadarData {
  id: string;
  profileImageUrl?: string | null;
  name: string;
  taken: string;
  booked: string;
  accrued: string;
  remaining: string;
}

export enum PeopleOnLeaveTypeEnum {
  Holiday = "HOLIDAY",
  Sick = "SICK",
  Other = "OTHER",
}

export const peopleOnLeaveTypeLabel: Record<PeopleOnLeaveTypeEnum, string> = {
  [PeopleOnLeaveTypeEnum.Holiday]: "Holiday Leave",
  [PeopleOnLeaveTypeEnum.Sick]: "Sick Leave",
  [PeopleOnLeaveTypeEnum.Other]: "Other leave",
};

export interface UsersLeaveStats {
  monthYear: string;
  reason: LeaveRequestsReason;
  totalUsers: number;
}
export interface FetchUsersLeaveStatsResponse {
  fetchUsersLeaveStats: UsersLeaveStats[];
}

export interface PeopleOnLeaveChartData {
  month: string;
  [PeopleOnLeaveTypeEnum.Holiday]: number;
  [PeopleOnLeaveTypeEnum.Sick]: number;
  [PeopleOnLeaveTypeEnum.Other]: number;
}
