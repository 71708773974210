import { Row, Col, Typography, Form } from "antd";
import { BaseButton, SuccessButton } from "components/Buttons";
import styled from "styled-components";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { usePreventUnsavedForm } from "contexts/PreventUnsavedFormProvider";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "components/core/Container";
import DATE_FORMAT from "constants/dateFormat";
import TIME_FORMAT from "constants/timeFormat";
import {
  EmergencyContact,
  EmployeeDetailArgs,
  ModifyEmergencyContact,
  ModifyJobDetailResponse,
  EditEmployeeDetailResponse,
  EmployeeFullDetail,
  EmployeeEditProfileFullDetail,
  JobDetail,
  PersonalDetailRef,
  EmployeeInitialWorkingCondition,
} from "model/Employee";
import { useMutation, useQuery } from "@apollo/client";
import { useNotify } from "services/api";
import {
  FETCH_EMPLOYEE_FULL_DETAIL,
  FETCH_MY_PROFILE_EDIT_DETAIL,
  UPSERT_MY_PROFILE_DETAIL,
  UPSERT_EMPLOYEE_DETAILS,
  FETCH_USER,
} from "services/graphql/employee";
import { useAuthContext } from "contexts";
import COLORS from "constants/colors";
import { WorkingTimeEnum } from "model/Common";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import { FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE } from "services/graphql/leave";
import { WorkingConditionPayload } from "model/AdminSetting";
import { calculateHoursPerWeek, formatWorkingDay, modifyWorkingConditionPayload } from "utils";
import { isAdmin } from "model/User";
import useUploadProfileImage from "./useUploadProfileImage";
import PersonalDetails from "./employeeEditFormFields/PersonalDetails";
import JobDetails from "./employeeEditFormFields/JobDetails";
import EmergencyDetails from "./employeeEditFormFields/EmergencyDetails";
import SalaryDetails from "./employeeEditFormFields/SalaryDetails";

const EmployeeEditForm = () => {
  const { user, refetch } = useAuthContext();
  const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);
  const [isImageFieldsChanged, setIsImageFieldsChanged] = useState<boolean>(false);
  const [initialWorkingCondition, setInitialWorkingCondition] = useState<EmployeeInitialWorkingCondition>();
  const [isDetailUpdatedSuccessfully, setIsDetailUpdatedSuccessfully] = useState<boolean>(false);
  const { showConfirmModal } = usePreventUnsavedForm();
  const navigate = useNavigate();
  const [form] = useForm();
  const notify = useNotify();
  const personalDetailRef = useRef<PersonalDetailRef>(null);

  const { id } = useParams();
  const userId = id || user?.id;
  const isMyProfile = userId === user?.id;
  const { updatePersonalDetails, updateJobDetails, updateEmergencyDetails, updateSalaryDetails } = PERMISSION;
  const { hasUpdatePersonalDetails, hasUpdateJobDetails, hasUpdateEmergencyDetails, hasUpdateSalaryDetails } =
    useCheckPermission({
      updatePersonalDetails,
      updateJobDetails,
      updateEmergencyDetails,
      updateSalaryDetails,
    });

  const fetchDetail = id ? FETCH_EMPLOYEE_FULL_DETAIL : FETCH_MY_PROFILE_EDIT_DETAIL;
  const mutateDetail = id
    ? UPSERT_EMPLOYEE_DETAILS(
        hasUpdatePersonalDetails,
        hasUpdateJobDetails,
        hasUpdateEmergencyDetails,
        hasUpdateSalaryDetails,
      )
    : UPSERT_MY_PROFILE_DETAIL;

  const onClose = () => {
    if (isFieldsChanged || isImageFieldsChanged) {
      showConfirmModal(() => {
        setIsFieldsChanged(false);
        navigate(-1);
      });
    } else {
      navigate(-1);
    }
  };

  const modifyJobDetailData = (jobDetail: JobDetail): ModifyJobDetailResponse => {
    const {
      workingCondition,
      id,
      startedAt,
      endedAt,
      departments,
      jobTitle,
      lineManagers,
      location,
      ...otherResponse
    } = jobDetail;
    const { workingTime, workingDays } = workingCondition;
    const baseData = { ...otherResponse, workingTime };

    const commonModifyData = {
      startedAt: startedAt ? dayjs(startedAt) : undefined,
      endedAt: endedAt ? dayjs(endedAt) : undefined,
      departmentIds: departments.map((item) => item.id),
      jobTitleId: jobTitle?.id,
      lineManagerIds: lineManagers.map((item) => item.id),
      locationId: location?.id,
    };

    if (workingTime === WorkingTimeEnum.Fixed) {
      const [{ startTime, endTime, lunchBreak }] = workingDays;
      const modifyData = {
        daysPerWeek: workingDays.map(({ weekDay }) => weekDay),
        startTime: dayjs(startTime, TIME_FORMAT.timeFormatWith24Hour),
        endTime: dayjs(endTime, TIME_FORMAT.timeFormatWith24Hour),
        lunchBreak,
      };
      return {
        ...baseData,
        ...commonModifyData,
        ...modifyData,
        hoursPerWeek: calculateHoursPerWeek({
          daysPerWeek: modifyData.daysPerWeek.length,
          startTimeData: modifyData.startTime,
          endTimeData: modifyData.endTime,
          lunchBreak: modifyData.lunchBreak,
        }),
      };
    }
    const modifyWorkingCondition = workingDays.reduce(
      (acc, day) => ({
        ...acc,
        [day.weekDay]: formatWorkingDay(day),
      }),
      {} as Record<string, WorkingConditionPayload>,
    );

    return {
      ...baseData,
      ...commonModifyData,
      workingCondition: modifyWorkingCondition,
      hoursPerWeek: calculateHoursPerWeek({ workingCondition: modifyWorkingCondition }),
    };
  };

  useQuery<EmployeeFullDetail | EmployeeEditProfileFullDetail>(fetchDetail, {
    variables: {
      ...(id
        ? {
            userId,
            hasFetchPersonalDetail: !hasUpdatePersonalDetails,
            hasFetchJobDetail: !hasUpdateJobDetails,
            hasFetchEmergencyContact: !hasUpdateEmergencyDetails,
            hasFetchSalary: !hasUpdateSalaryDetails,
          }
        : {}),
    },
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
    onCompleted: (response) => {
      if (response) {
        const responseData = !id
          ? (response as EmployeeEditProfileFullDetail)?.fetchEditProfileOverview
          : (response as EmployeeFullDetail);
        const emergencyDetail = responseData.fetchEmergencyContact
          ? Object.keys(responseData.fetchEmergencyContact).reduce(
              (prev, key) => ({
                ...prev,
                [`${
                  key.charAt(0) !== "_" && key !== "id" ? `emergency${key.charAt(0).toUpperCase() + key.slice(1)}` : key
                }`]: responseData.fetchEmergencyContact?.[key as keyof EmergencyContact],
              }),
              {} as ModifyEmergencyContact,
            )
          : {};
        const jobDetail =
          id && responseData.fetchJobDetail
            ? modifyJobDetailData(responseData.fetchJobDetail)
            : ({} as ModifyJobDetailResponse);

        const salaryDetails = id && responseData.fetchSalary ? responseData.fetchSalary : {};
        if (Object.keys(jobDetail).length) {
          setInitialWorkingCondition(
            jobDetail.workingTime === WorkingTimeEnum.Fixed
              ? {
                  startTime: jobDetail?.startTime,
                  endTime: jobDetail?.endTime,
                  workingTime: jobDetail?.workingTime,
                  daysPerWeek: jobDetail.daysPerWeek,
                  lunchBreak: jobDetail?.lunchBreak,
                  hoursPerWeek: jobDetail?.hoursPerWeek,
                }
              : {
                  workingTime: jobDetail.workingTime,
                  workingCondition: jobDetail.workingCondition,
                  hoursPerWeek: jobDetail?.hoursPerWeek,
                },
          );
        }
        form.setFieldsValue({
          ...jobDetail,
          ...emergencyDetail,
          ...salaryDetails,
          ...responseData.fetchPersonalDetail,
          dateOfBirth:
            responseData?.fetchPersonalDetail?.dateOfBirth && dayjs(responseData.fetchPersonalDetail.dateOfBirth),
        });
      }
    },
  });

  const handleProfileImageMutation = () => {
    if (personalDetailRef?.current?.profilePicture) {
      mutateUpload({
        variables: {
          userId,
          profileImage: personalDetailRef.current.profilePicture,
        },
      });
      setIsFieldsChanged(false);
    } else if (personalDetailRef?.current?.removeProfilePicture) {
      mutateDestroyProfile({ variables: { userId } });
      setIsFieldsChanged(false);
    }
  };

  const [mutate, { loading }] = useMutation<EditEmployeeDetailResponse>(mutateDetail, {
    onCompleted: (response) => {
      const { upsertPersonalDetail, upsertJobDetail, upsertEmergencyContact, upsertSalary } = response;
      if (upsertPersonalDetail?.errors) {
        notify.error(upsertPersonalDetail.errors.fullMessages);
      }
      if (upsertJobDetail?.errors) {
        notify.error(upsertJobDetail.errors.fullMessages);
      }
      if (upsertEmergencyContact?.errors) {
        notify.error(upsertEmergencyContact.errors.fullMessages);
      }
      if (upsertSalary?.errors) {
        notify.error(upsertSalary.errors.fullMessages);
      }

      const permissions = {
        hasUpdatePersonalDetails: hasUpdatePersonalDetails ? "hasUpdatePersonalDetails" : `notFetchPersonalDetail`,
        hasUpdateJobDetails: hasUpdateJobDetails ? "hasUpdateJobDetails" : `notUpdateJobDetails`,
        hasUpdateEmergencyDetails: hasUpdateEmergencyDetails
          ? "hasUpdateEmergencyDetails"
          : `notUpdateEmergencyDetails`,
        hasUpdateSalaryDetails: hasUpdateSalaryDetails ? "hasUpdateSalaryDetails" : `notUpdateSalaryDetails`,
      };

      const upsertDetails = {
        [permissions.hasUpdatePersonalDetails]: !!upsertPersonalDetail?.personalDetail,
        [permissions.hasUpdateJobDetails]: !!upsertJobDetail?.jobDetail,
        [permissions.hasUpdateEmergencyDetails]: !!upsertEmergencyContact?.emergencyContact,
        [permissions.hasUpdateSalaryDetails]: !!upsertSalary?.salary,
      };

      const checkSuccess = Object.keys(permissions)
        .filter((item) => Object.keys(upsertDetails).includes(item))
        .map((item) => upsertDetails[item])
        .every((item) => !!item);
      setIsDetailUpdatedSuccessfully(checkSuccess);
      if (id && checkSuccess) {
        if (
          !isImageFieldsChanged &&
          !personalDetailRef?.current?.profilePicture &&
          !personalDetailRef?.current?.removeProfilePicture
        ) {
          notify.success({ message: "Update employee detail successfully." });
          navigate(-1);
        } else if (isImageFieldsChanged) {
          handleProfileImageMutation();
        }
      }

      if (!id && upsertPersonalDetail?.personalDetail && upsertEmergencyContact?.emergencyContact) {
        if (isMyProfile) {
          refetch();
        }
        if (
          !isImageFieldsChanged &&
          !personalDetailRef?.current?.profilePicture &&
          !personalDetailRef?.current?.removeProfilePicture
        ) {
          notify.success({ message: "Update profile successfully." });
          navigate(-1);
        } else if (isImageFieldsChanged) {
          handleProfileImageMutation();
        }
      }
    },
    refetchQueries: [
      ...(id ? [{ query: FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE, variables: { userId } }] : []),
      {
        query: fetchDetail,
        variables: {
          ...(id
            ? {
                userId,
                hasFetchPersonalDetail: !hasUpdatePersonalDetails,
                hasFetchJobDetail: !hasUpdateJobDetails,
                hasFetchEmergencyContact: !hasUpdateEmergencyDetails,
                hasFetchSalary: !hasUpdateSalaryDetails,
              }
            : {}),
        },
      },
      ...(isAdmin(user) && id
        ? [
            {
              query: FETCH_USER,
              variables: {
                userIds: [userId],
                fetchBirthdaysThisWeek: false,
                fetchWorkAnniversaryThisWeek: false,
                showLeavers: true,
                fetchHolidayBalance: true,
              },
            },
          ]
        : []),
    ],
  });

  const { mutate: mutateUpload, loading: uploading, mutateDestroyProfile } = useUploadProfileImage(true);

  const onFinish = (data: EmployeeDetailArgs) => {
    const {
      daysPerWeek,
      workingCondition: workingConditionPayload,
      startTime,
      endTime,
      hoursPerWeek,
      profilePicture,
      lunchBreak,
      accumulatedCarriedHolidays,
      ...restPayloadData
    } = data;

    const workingDays = modifyWorkingConditionPayload({
      workingTime: restPayloadData.workingTime,
      daysPerWeek,
      startTimeData: startTime,
      endTimeData: endTime,
      lunchBreak,
      workingConditionPayload,
    });
    if (isFieldsChanged) {
      mutate({
        variables: {
          ...restPayloadData,
          ...(id
            ? {
                workingDays,
                startedAt: data.startedAt ? dayjs(data.startedAt).format(DATE_FORMAT.isoFormat) : null,
                endedAt: data.endedAt ? dayjs(data.endedAt).format(DATE_FORMAT.isoFormat) : null,
                accumulatedCarriedHolidays: accumulatedCarriedHolidays ?? null,
              }
            : {}),
          dateOfBirth: data.dateOfBirth ? dayjs(data.dateOfBirth).format(DATE_FORMAT.isoFormat) : null,
          userId,
        },
      });
    }
    if (!isFieldsChanged && isImageFieldsChanged) {
      handleProfileImageMutation();
    }
  };
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onValuesChange={(val) => {
        if (Object.keys(val)[0] !== "profilePicture") {
          setIsFieldsChanged(!!val);
        }
      }}
    >
      <Row className="align-items-center">
        <Col span={12}>
          <StyledTitle level={2}>Edit employee</StyledTitle>
        </Col>
      </Row>
      <Container className="p-0 section-box-shadow">
        <StyledContainer>
          {(hasUpdatePersonalDetails || !id) && (
            <PersonalDetails
              form={form}
              isMyProfile={isMyProfile}
              setIsImageFieldsChanged={setIsImageFieldsChanged}
              id={id}
              ref={personalDetailRef}
              user={user}
            />
          )}
          {id && hasUpdateJobDetails && (
            <JobDetails
              id={id}
              form={form}
              hasUpdateJobDetails={hasUpdateJobDetails}
              setIsFieldsChanged={setIsFieldsChanged}
              initialWorkingCondition={initialWorkingCondition}
            />
          )}
          {(hasUpdateEmergencyDetails || !id) && <EmergencyDetails />}
          {id && hasUpdateSalaryDetails && <SalaryDetails />}
        </StyledContainer>

        <StyledFooterContainer className="border-top">
          <Row>
            <Col span={24}>
              <SuccessButton htmlType="submit" loading={loading || uploading}>
                Save changes
              </SuccessButton>
              <BaseButton className="mx-3" onClick={onClose}>
                Cancel
              </BaseButton>
            </Col>
          </Row>
        </StyledFooterContainer>
      </Container>
    </Form>
  );
};

export default EmployeeEditForm;

const StyledContainer = styled(Container)`
  padding-left: 33px;
  padding-right: 33px;
  margin-top: 28px;
  & .profile-picture {
    color: ${COLORS.labelColor};
    opacity: 0.8;
    font-weight: 500;
  }
  & .profilePicture .ant-form-item-explain.ant-form-item-explain-connected {
    margin-bottom: 15px;
  }

  & .gender .ant-radio-group {
    display: grid;
    grid-template-columns: auto auto auto;

    .ant-radio-button-wrapper {
      white-space: nowrap;
      padding-inline: unset;
      height: 36px;
    }
  }
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 22px;
    margin-bottom: 0px;
  }
`;

const StyledFooterContainer = styled(Container)`
  padding: 21px 33px 21px 33px;
  border-radius: 0px 0px 4px 4px;
`;
