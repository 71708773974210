import { Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { SorterResult } from "antd/lib/table/interface";
import { DefaultButton } from "components/Buttons";
import Icon from "components/Icon";
import { sortingTableData, SortOrderEnum, SortTypeEnum, tableColumnCommonData } from "components/Table";
import DATE_FORMAT from "constants/dateFormat";
import TABLE from "constants/table";
import { useFetchRequestList } from "contexts/LeaveRequestListProvider";
import dayjs from "dayjs";
import { getFilenameFromUrl } from "features/employee/leave";
import { DocumentsAssociatedWithLeave } from "model/Document";
import { leaveTypeLabels } from "model/Leave";
import { useCallback, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { downloadLink } from "utils";

const DocumentsListTable = () => {
  const { id } = useParams();
  const {
    documentsAssociatedWithLeaveLoading,
    refetchDocumentAssociatedWithLeave,
    documentAssociatedWithLeave,
    isRefetchAssociatedWithLeave,
    setIsRefetchAssociatedWithLeave,
  } = useFetchRequestList();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DocumentsAssociatedWithLeave>>({});

  const columns: ColumnsType<DocumentsAssociatedWithLeave> = [
    {
      ...tableColumnCommonData({
        title: "Document name",
        dataIndex: "attachmentUrl",
        defaultSortOrder: SortOrderEnum.Descend,
        width: "30%",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.String,
          firstData: getFilenameFromUrl(a.attachmentUrl as string),
          secondData: getFilenameFromUrl(b.attachmentUrl as string),
        }),
      render: (_, { attachmentUrl }) => (
        <StyledDocumentName level={3} className="m-0 d-flex gap-2 py-2">
          <Icon name="document" />
          {getFilenameFromUrl(attachmentUrl as string)}
        </StyledDocumentName>
      ),
    },
    {
      ...tableColumnCommonData({
        title: "Leave type",
        dataIndex: "reason",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { reason }) => <span>{leaveTypeLabels[reason!]}</span>,
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.String,
          firstData: leaveTypeLabels[a.reason!],
          secondData: leaveTypeLabels[b.reason!],
        }),
    },
    {
      ...tableColumnCommonData({
        title: "Start date",
        dataIndex: "fromDate",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { fromDate }) => <span>{fromDate ? dayjs(fromDate).format(DATE_FORMAT.fullDateDefault) : "-"}</span>,
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.fromDate, secondData: b.fromDate }),
    },
    {
      ...tableColumnCommonData({
        title: "End date",
        dataIndex: "toDate",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { toDate }) => <span>{toDate ? dayjs(toDate).format(DATE_FORMAT.fullDateDefault) : "-"}</span>,
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.toDate, secondData: b.toDate }),
    },
    {
      title: "Notes",
      ellipsis: {
        showTitle: false,
      },
      render: (_, { employeeNote }) =>
        employeeNote?.body ? (
          <Tooltip placement="top" title={<StyledTooltipTitle>{employeeNote?.body}</StyledTooltipTitle>}>
            <StyledNotes>{employeeNote?.body}</StyledNotes>
          </Tooltip>
        ) : (
          <span>/</span>
        ),
    },
    {
      title: "",
      dataIndex: "download",
      align: "right",
      render: (_, { attachmentUrl }) => (
        <DefaultButton
          onClick={(event) => {
            event.stopPropagation();
            if (attachmentUrl) {
              downloadLink(attachmentUrl, getFilenameFromUrl(attachmentUrl));
            }
          }}
        >
          Download
        </DefaultButton>
      ),
    },
  ];

  const fetchData = useCallback(
    async (cursor?: string) => {
      const pageInfo = await refetchDocumentAssociatedWithLeave({ userId: id, cursor });
      if (pageInfo?.hasNextPage && pageInfo.endCursor) {
        fetchData(pageInfo.endCursor);
      }
    },
    [id, refetchDocumentAssociatedWithLeave],
  );

  useLayoutEffect(() => {
    if (isRefetchAssociatedWithLeave) {
      fetchData();
      setIsRefetchAssociatedWithLeave(false);
    }
  }, [fetchData, isRefetchAssociatedWithLeave, setIsRefetchAssociatedWithLeave]);

  useLayoutEffect(() => {
    fetchData();
    setIsRefetchAssociatedWithLeave(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table
      columns={columns}
      dataSource={documentAssociatedWithLeave}
      pagination={false}
      loading={documentsAssociatedWithLeaveLoading}
      rowKey="id"
      scroll={{
        scrollToFirstRowOnChange: false,
        y: TABLE.tableDefaultHeight,
      }}
      sortDirections={TABLE.tableSortDirections}
      onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<DocumentsAssociatedWithLeave>)}
    />
  );
};

export default DocumentsListTable;

const StyledDocumentName = styled(Typography.Title)`
  font-size: 14px;
`;

const StyledTooltipTitle = styled.div`
  text-align: center;
  line-height: 22px;
  padding: 10px 16px;
`;

const StyledNotes = styled.span`
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
