import { Typography } from "antd";
import COLORS from "constants/colors";
import DATE_FORMAT from "constants/dateFormat";
import dayjs from "dayjs";
import { useDebounce } from "hooks";
import { LineChartData, UserCountStats } from "model/Insights";
import { useState } from "react";
import {
  CartesianGrid,
  DotProps,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

type CustomActiveDotsProps = {
  cx?: number;
  cy?: number;
  strokeColor: string;
};

type Props = {
  data?: UserCountStats[];
};

type CustomizedAxisTickProps = { x: number; y: number; label: string; activeLabel?: string };

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <StyledToolTipWrapper>
        <Typography.Title level={4}>{label}</Typography.Title>
        <StyledLegend className="employees">
          Total employees: <Typography.Title level={4}>{payload[0].value}</Typography.Title>
        </StyledLegend>
        <StyledLegend className="joiners">
          Joiners: <Typography.Title level={4}>{payload[1].value}</Typography.Title>
        </StyledLegend>
        <StyledLegend className="leavers">
          Leavers: <Typography.Title level={4}>{payload[2].value}</Typography.Title>
        </StyledLegend>
      </StyledToolTipWrapper>
    );
  }
  return null;
};

const CustomActiveDots = ({ cx, cy, strokeColor }: CustomActiveDotsProps) =>
  cx && cy ? (
    <svg
      x={cx - 5}
      y={cy - 5}
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5.5" cy="5.5" r="4.5" fill="white" stroke={strokeColor} strokeWidth="2" />
    </svg>
  ) : (
    <></>
  );

const CustomizedAxisTick = ({ x, y, label, activeLabel }: CustomizedAxisTickProps) => (
  <g>
    {label === activeLabel && (
      <defs>
        <filter
          id={label.replace(" ", "_")}
          x={label.length === 3 ? "-45%" : "-15%"}
          width={label.length === 3 ? "180%" : "130%"}
          y="-20%"
          height="140%"
        >
          <feFlood floodColor={COLORS.xAxisLabelBgColor} />
          <feGaussianBlur stdDeviation="2" />
          <feComponentTransfer>
            <feFuncA type="table" tableValues="0 0 0 1" />
          </feComponentTransfer>
          <feComponentTransfer>
            <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
          </feComponentTransfer>
          <feComposite operator="over" in="SourceGraphic" />
        </filter>
      </defs>
    )}
    <StyledXAxisText filter={`url(#${label.replace(" ", "_")})`} x={x} y={y} dy={16} textAnchor="middle">
      {label}
    </StyledXAxisText>
  </g>
);

const NoOfEmployeesChart = ({ data }: Props) => {
  const [activeXAxisLabel, setActiveXAxisLabel] = useState<string>();
  const activeXAxisLabelDeferredValue = useDebounce<string | undefined>(activeXAxisLabel, 100);

  const modifyData = (data?: UserCountStats[]): LineChartData[] => {
    if (data) {
      const modifyData = data.map((item) => ({
        month:
          dayjs(item.monthYear).month() === 0
            ? dayjs(item.monthYear).format(DATE_FORMAT.monthAndYear)
            : dayjs(item.monthYear).format(DATE_FORMAT.shortMonth),
        totalEmployees: item.totalUsers,
        joiners: item.usersStartedInMonth,
        leavers: item.usersLeftInMonth,
      }));
      return modifyData;
    }
    return [] as LineChartData[];
  };

  return (
    <StyledWrapper>
      <ResponsiveContainer height={194} width="100%">
        <LineChart
          data={modifyData(data)}
          margin={{ top: 20, right: 25, left: 0, bottom: 0 }}
          onMouseMove={(props) =>
            setActiveXAxisLabel((prev) => (prev !== props.activeLabel ? props.activeLabel : prev))
          }
        >
          <CartesianGrid strokeDasharray="3 0" stroke={COLORS.xAxisStrokeColor} vertical={false} />
          <XAxis
            dataKey="month"
            axisLine={false}
            tickLine={false}
            minTickGap={0}
            tick={({ x, y, payload }) => (
              <CustomizedAxisTick x={x} y={y} label={payload.value} activeLabel={activeXAxisLabelDeferredValue} />
            )}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tick={{ fontSize: 10, fontWeight: 500, color: COLORS.noColor }}
            tickMargin={10}
          />
          <Tooltip content={<CustomTooltip />} cursor={{ stroke: COLORS.toolTipStrokeColor, strokeDasharray: "3 3" }} />
          <Line
            type="monotone"
            dataKey="totalEmployees"
            stroke={COLORS.employeesLineColor}
            strokeWidth={2}
            dot={false}
            activeDot={({ cx, cy }: DotProps) => (
              <CustomActiveDots cx={cx} cy={cy} strokeColor={COLORS.employeesLineColor} />
            )}
            filter="url(#totalEmployeesLineShadow)"
          />
          <Line
            type="monotone"
            dataKey="joiners"
            stroke={COLORS.joinersLineColor}
            strokeWidth={2}
            dot={false}
            activeDot={({ cx, cy }: DotProps) => (
              <CustomActiveDots cx={cx} cy={cy} strokeColor={COLORS.joinersLineColor} />
            )}
            filter="url(#joinersLineShadow)"
          />
          <Line
            type="monotone"
            dataKey="leavers"
            stroke={COLORS.leaversLineColor}
            strokeWidth={2}
            dot={false}
            activeDot={({ cx, cy }: DotProps) => (
              <CustomActiveDots cx={cx} cy={cy} strokeColor={COLORS.leaversLineColor} />
            )}
            filter="url(#leaversLineShadow)"
          />
          <defs>
            <filter id="totalEmployeesLineShadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow dx="0" dy="4" stdDeviation="2.5" floodColor={COLORS.employeesLineShadow} />
            </filter>
            <filter id="joinersLineShadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow dx="0" dy="4" stdDeviation="2.5" floodColor={COLORS.joinersLineShadow} />
            </filter>
            <filter id="leaversLineShadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow dx="0" dy="4" stdDeviation="2.5" floodColor={COLORS.leaversLineShadow} />
            </filter>
          </defs>
        </LineChart>
      </ResponsiveContainer>
      <StyledLegendWrapper>
        <StyledLegend className="employees">Total employees</StyledLegend>
        <StyledLegend className="joiners">Joiners</StyledLegend>
        <StyledLegend className="leavers">Leavers</StyledLegend>
      </StyledLegendWrapper>
    </StyledWrapper>
  );
};

export default NoOfEmployeesChart;

const StyledWrapper = styled.div`
  padding-bottom: 23px;
  width: 80%;
  padding-right: 20px;
`;

const StyledLegend = styled.div`
  font-size: 12px;
  color: ${COLORS.inputFieldTextColor};
  display: flex;
  align-items: center;
  &:before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 1px;
    margin-right: 6px;
  }
  &.employees:before {
    background: ${COLORS.employeesLineColor};
  }
  &.joiners:before {
    background: ${COLORS.joinersLineColor};
  }
  &.leavers:before {
    background: ${COLORS.leaversLineColor};
  }
`;

const StyledLegendWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 14px;
  gap: 2vw;
  padding: 0px 25px 0px 48px;
`;

const StyledToolTipWrapper = styled.div`
  padding: 12px;
  background: ${COLORS.white};
  border-radius: 6px;
  border: 1px solid ${COLORS.toolTipBorderColor};
  box-shadow: 0px 2px 6px 0px ${COLORS.tooTipBoxShadowColor};
  display: flex;
  flex-direction: column;
  gap: 5px;

  & .ant-typography {
    margin: 0px;
    font-size: 12px;
    color: ${COLORS.inputFieldTextColor};
  }
`;

const StyledXAxisText = styled.text`
  font-size: 11px;
  font-weight: 500;
  fill: ${COLORS.noColor};
  color: ${COLORS.noColor};
`;
