import { expectNumber } from "utils";
import storage from "utils/storage";
import { EnhancedLeaveRequest } from "./Leave";
import { User } from "./User";
import {
  BirthdaysThisWeek,
  ProbationaryPeriodEndingThisWeek,
  StartingThisWeek,
  WorkAnniversaryThisWeek,
} from "./Calendar";
import { DocumentActionDataModify, DocumentReviewDataType, Documents, UserActionConnection } from "./Document";

export enum NotificationType {
  CreateHoliday = "create_holiday",
  Holiday = "holiday",
  Other = "other",
  Document = "document",
  MyDocument = "my_document",
}
export type NotificationStorage = {
  id: number;
  message: string;
  type: NotificationType;
};

export type OtherUpdateData = {
  id: number;
  name: string;
  message: string;
};

export type messageItem =
  | BirthdaysThisWeek
  | ProbationaryPeriodEndingThisWeek
  | StartingThisWeek
  | WorkAnniversaryThisWeek;

type EnhancedLeaveRequestNotification = Omit<EnhancedLeaveRequest, "id"> & {
  user: Pick<User, "name" | "id">;
  leaveRequestId: string;
};

export type HolidayNotification = Omit<EnhancedLeaveRequestNotification, "user"> & {
  id: string;
};
export interface LeaveRequestCreatedResponse {
  leaveRequestCreated: {
    leaveRequest: EnhancedLeaveRequestNotification;
  } | null;
}
export interface LeaveRequestApprovedResponse {
  leaveRequestApproved: {
    leaveRequest: EnhancedLeaveRequestNotification;
  } | null;
}
export interface LeaveRequestDeclinedResponse {
  leaveRequestDeclined: {
    leaveRequest: EnhancedLeaveRequestNotification;
  } | null;
}

export interface BirthdayComingInResponse {
  birthdayComingIn: {
    user: Pick<User, "name"> & { dateOfBirth: string };
  } | null;
}

export interface FinishingInResponse {
  finishingIn: {
    user: Pick<User, "name"> & { leavingRequest: { leavingAt: string } };
  } | null;
}

export interface ProbationaryPeriodEndingResponse {
  probationaryPeriodEnding: {
    user: Pick<User, "name"> & { startedAt: string; probationaryPeriod: number };
  } | null;
}

export interface StartingInResponse {
  startingIn: {
    user: Pick<User, "name"> & { startedAt: string };
  } | null;
}

export interface WorkAnniversaryResponse {
  workAnniversary: {
    user: Pick<User, "name"> & { startedAt: string };
  } | null;
}

export const notificationsFilteredData = <
  T extends EnhancedLeaveRequest | OtherUpdateData | DocumentActionDataModify | DocumentReviewDataType,
>(
  data: T[],
  type: NotificationType,
): T[] =>
  data?.filter(
    (item) =>
      !storage
        .getReadNotification()
        .find(
          (notification) =>
            notification.id ===
              expectNumber(
                type === NotificationType.Holiday
                  ? `${(item as EnhancedLeaveRequest)?.user?.id}${item.id}`
                  : `${(item as OtherUpdateData | DocumentActionDataModify | DocumentReviewDataType).id}`,
              ) && notification.type === type,
        ),
  );

export interface DocumentSharedNotificationResponse {
  documentShared: {
    document: Pick<Documents, "id" | "attachmentName">;
  };
}

export interface OtherNotificationMsgArg {
  date: string;
  name?: string;
  probationaryPeriod?: number;
}

export interface LeaveNotificationMsgArg {
  fromDate: string;
  toDate: string;
  name: string;
}

export type OtherNotificationMsg = (data: OtherNotificationMsgArg) => string;
export type LeaveNotificationMsg = (data: LeaveNotificationMsgArg) => string;

export interface NotificationMsg {
  [x: string]: OtherNotificationMsg | LeaveNotificationMsg;
}

export enum NotificationDrawerTab {
  Employees = "EMPLOYEE",
  My = "My",
}

export interface FetchDocumentActionPendingUserList {
  fetchDocument: {
    id: string;
    userDocumentConnections: {
      userActionConnection: UserActionConnection;
      user: Pick<User, "id" | "name" | "profileImageUrl">;
    }[];
  };
}

export interface ModifyMyLeaveNotification extends EnhancedLeaveRequest {
  type: NotificationType;
  notificationTimeStamp: string;
}

export interface ModifyMyDocumentNotification extends DocumentReviewDataType {
  type: NotificationType;
  notificationTimeStamp: string;
}
