const COLORS = {
  // LAYOUT
  layoutBgColor: "#F4F5F9",
  secondaryLayoutBgColor: "#F0EFFF",

  // BUTTONS -------
  successColor: "#9AEDAB",
  successSecondaryColor: "#68CF7D",
  successDarkColor: "#47B252",
  successHover: "#B0FDC0",
  successActive: "#90E1A0",
  linkHover: "#3E4F64",
  linkDisabledColor: "#D8DBDF",
  border: "#C0C0C0",
  lightBorder: "#d8dbdf",
  outlineColor: "#182739",
  primaryColor: "#182739",
  white: "#ffffff",
  tertiary: "#29349D",
  activeNavbar: "#233447",
  lightActiveNavbar: "#233447b3",
  cancelColor: "#FDB495",
  cancelHover: "#FFCAB5",
  cancelActive: "#EFA889",
  primaryHover: "#3E4F64",
  primaryActive: "#0F151D",
  defaultColor: "#F1F2F3",
  defaultColorHover: "#E9E9ED",
  defaultActive: "#E4E6E9",
  dangerColor: "#E65656",
  disabledColor: "#778496",
  disabledBgColor: "#F1F1F1",
  baseBorderColor: "#E4E6E9",
  baseHoverBgColor: "#F7F7F7",
  baseActive: "#E4E6E9",
  takenBarColor: "#82E696",
  bookedBarColor: "#C9A7FF",
  requestedBarColor: "#EFC739",
  referenceBarColor: "#EB5757",
  buttonBorderColor: "#a5adcc",
  buttonFocusBorderColor: "#121232",
  loggedMessageColor: "#D94E4E",
  loggedInBorderColor: "#E37F7F",
  notificationSuccessBgColor: "#daf2df",
  segmentedDisabledColor: "#6a727d",

  colorText: "#59576d",
  colorTextSecondary: "#7a7a7a",
  colorTextDisabled: "#b1bace",
  colorTextDisabledSecondary: "#6a727d",

  // LEAVE TYPE
  sick: "#dfd1fa",
  sickDarkCurve: "#D9C6FC",
  sickLightCurve: "#DECFFA",
  sickShadow: "#E5DBF5",
  carers: "#f0c5b6",
  carersDarkCurve: "#EFB9A7",
  carersLightCurve: "#EEC4B6",
  carersShadow: "#EBC5B7",
  paternity: "#b5f0c3",
  paternityDarkCurve: "#A6E9B7",
  paternityLightCurve: "#B3EEC3",
  paternityShadow: "#b1fcc29e",
  jury_service: "#c6fcfd",
  jury_serviceDarkCurve: "#BBF4F6",
  jury_serviceLightCurve: "#CBFAFC",
  jury_serviceShadow: "#CDFCFD",
  adoptive: "#f2e6a2",
  adoptiveDarkCurve: "#E9DC95",
  adoptiveLightCurve: "#F2E6A2",
  adoptiveShadow: "#F1E9BD",
  compassionate: "#bdd6fb",
  compassionateDarkCurve: "#ACCAF7",
  compassionateLightCurve: "#BDD6FB",
  compassionateShadow: "#D0E2FF",
  parental: "#eec0e2",
  parentalDarkCurve: "#E8B5DB",
  parentalLightCurve: "#EEC0E2",
  parentalShadow: "#EEC0E2",
  force_majeure: "#fbcecf",
  force_majeureDarkCurve: "#F4B9BB",
  force_majeureLightCurve: "#FBCDCE",
  force_majeureShadow: "#FFDCDD",
  maternity: "#f5c4f7",
  maternityDarkCurve: "#EEB7F0",
  maternityLightCurve: "#F1C2F3",
  maternityShadow: "#F7BAFA",
  unpaid: "#d5dcea",
  unpaidDarkCurve: "#C2CDE1",
  unpaidLightCurve: "#D5DCEA",
  unpaidShadow: "#D0D8E8",
  time_in_lieu: "#b5e3fe",
  time_in_lieuDarkCurve: "#9AD6FA",
  time_in_lieuLightCurve: "#B5E3FE",
  time_in_lieuShadow: "#C2E9FF",
  other: "#c9ccfa",
  otherDarkCurve: "#BEC1FF",
  otherLightCurve: "#C8CCFA",
  otherShadow: "#C5C8FF",

  errorColor: "#be2c2c",
  errorLightColor: "#E55656",
  warningColor: "#E7B128",
  warningMessageColor: "#F8E9C4",
  warningLightBgColor: "#FFF3D3",
  lightErrorColor: "#F5D7D7",

  // BREADCRUMBS
  breadCrumbs: "#62AB71",

  // BACKGROUNDS AND BORDERS
  headerText: "#778496",
  errorTextBgColor: "#f9ebeb",
  errorTextColor: "#cc2020",
  inputFieldTextColor: "#2F3944",
  labelColor: "#6C747F",
  inputBorderHover: "#283645",
  inputBorderColor: "#D1D4D8",
  inputPlaceHolderTextColor: "#6D737E",
  inputErrorBorderHover: "#d97e77",
  infoContainerBgColor: "#e0e7ff",
  inputBoxShadowColor: "#01090e8f",
  myLeaveBgColor: "#EEF0F5",
  lightBoxShadowColor: "#0000000F",
  segmentedBoxShadowColor: "#E0E2EA",
  skeletonBgColor: "#C0C0C059",
  linearBgColor: "#f1f0fe",

  // RADIO BUTTONS
  radioBgColor: "#e7dafc",
  radioBgHover: "#f3ecfd",
  radioBorder: "#A591C7",

  borderLight: "#E4E6E9",
  backgroundLight: "#F4F6FB",
  tableBgColor: "#F8F9FD",
  rowBgLightColor: "#f4f5f8",
  disabledIconColor: "#D8DBDF",

  scrollTrackColor: "#E8E8E8",
  scrollThumbColor: "#CDCDCD",

  lineColor: "#e7e9f0",
  downloadIconBg: "#D9DDFF",
  holidayBgColor: "#9765e7",
  holidayBgLightColor: "#D9C1FF",
  leaveBgColor: "#ed8052",
  leaveBgLightColor: "#f6c5b2",
  borderShadowColor: "#f1f2f5cc",
  boxShadowColor: "#e1e1e380",
  tagColor: "#53905f",
  tagBgColor: "#e3fae8",
  headerTextBold: "#121232",

  noBgColor: "#E9EBF1",
  noColor: "#778496",
  badgeColor: "#f3d744",

  // BILLING
  billingFirstSectionBgColor: "#ebe0fd",
  billingFirstSectionShadowColor: "#E9DCFF",
  billingTitleColor: "#927DB5",
  discountBgColor: "#DEC9FF",
  discountColor: "#834FD7",

  // HOLIDAY ALLOWANCE
  /*
   here we are using this type of casing instead of camel case
   because we already have this kind of casing in leave card svg 
  */
  holiday_per_annum: "#4E3282",
  holiday_per_annumBgColor: "#ebddff",
  holiday_per_annumDarkCurve: "#DECDFF",
  holiday_per_annumLightCurve: "#E5D5FF",
  holiday_per_annumShadow: "#E5DBF5",
  holiday_year_to_year: "#3E5F45",
  holiday_year_to_yearBgColor: "#e1ffe7",
  holiday_year_to_yearDarkCurve: "#C5F4CF",
  holiday_year_to_yearLightCurve: "#D8FCDF",
  holiday_year_to_yearShadow: "#DDFEE4",

  defaultTagBgColor: "#E9ECEF",
  infoSvgPrimary: "#283A4F",

  // SUBSCRIPTION
  noSubscriptionBg: "#BCFEFE",
  noSubscriptionBorder: "#92E5E7",
  subscriptionDarkCurve: "#A3DDE5",
  subscriptionLightCurve: "#C8F8FF",

  // TABLE
  rowBgHover: "#f8f8fb",
  darkRowBgHover: "#F2F3F8",

  // CALENDAR
  dayOffDateBg: "#E2E4EC",
  dayOffDateColor: "#A2ABB6",

  // HOC
  hocColor: "#E55656",

  // LEAVE STATUS
  approvedStatusBoxColor: "#9AEDAB",
  declinedStatusBoxColor: "#E55656",
  pendingStatusBoxColor: "#EFC739",

  // LINE CHART
  employeesLineColor: "#766AFE",
  employeesLineShadow: "#E0DDFF",
  joinersLineColor: "#75D88C",
  joinersLineShadow: "#E0F5E5",
  leaversLineColor: "#EFC739",
  leaversLineShadow: "#FFE2B7",
  toolTipStrokeColor: "#D7DCE3",
  xAxisStrokeColor: "#E3E4E9",
  dividerBorderColor: "#E8EAF0",
  toolTipBorderColor: "#E9EBF1",
  tooTipBoxShadowColor: "#a19bdf33",
  xAxisLabelBgColor: "#F4F6FC",

  // BAR CHART
  holidayLeaveBar: "#766AFE",
  sickLeaveBar: "#F18E47",
  otherLeaveBar: "#F8D868",
  salaryBar: "#6D62ED",
  toolTipBgColor: "#EAECF4",

  // PIE CHART
  firstCellColor: "#544CB4",
  secondCellColor: "#8077F1",
  thirdCellColor: "#AF8AFF",
  fourthCellColor: "#F18E47",
  fifthCellColor: "#EFC739",
  sixthCellColor: "#C3C4D4",

  // PROGRESS CHART
  outerContainerBoxShadow: "#DCDAFF",
  outerContainerGradientColor: "#F9F8FE",
  successCheckColor: "#82E095",
  innerDotedBorderColor: "#B3A1EC",
  innerIconColor: "#D8D7F0",
  notLoggedInStartGradientColor: "#F0B23F",
  notLoggedInEndGradientColor: "#F59D65",
  loggedInStartGradientColor: "#8F79F6",
  loggedInEndGradientColor: "#6D62ED",
};

export type ColorType = keyof typeof COLORS;
export default COLORS;
