import { Col, Empty, Row, Segmented, Typography } from "antd";
import { Container } from "components/core";
import {
  BreakdownFilterEnum,
  breakdownFilterLabel,
  FetchUsersGroupStatsResponse,
  ModifyUserGroupStatsData,
  UsersGroupStats,
} from "model/Insights";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { enumValues } from "utils/misc";
import { useQuery } from "@apollo/client";
import { FETCH_USERS_GROUP_STATS } from "services/graphql/insights";
import dayjs from "dayjs";
import COLORS from "constants/colors";
import DATE_FORMAT from "constants/dateFormat";
import BreakdownPieChart from "./BreakdownPieChart";
import EmployeeBreakdownTable from "./EmployeeBreakdownTable";

const EmployeesBreakdown = () => {
  const [groupBy, setGroupBy] = useState<BreakdownFilterEnum>(BreakdownFilterEnum.Department);
  const [data, setData] = useState<ModifyUserGroupStatsData[]>([]);
  const [activeLabelIndex, setActiveLabelIndex] = useState<number>();

  const modifyResponse = (data: UsersGroupStats[]) => {
    if (data.length) {
      if (groupBy === BreakdownFilterEnum.Gender) return data;
      const sortData = data.sort((a, b) => b.usersCount - a.usersCount);
      if (sortData.length > 5) {
        const firstFive = sortData.slice(0, 5);
        const otherTotal = sortData.slice(5).reduce((sum, item) => sum + item.usersCount, 0);
        return [...firstFive, { name: "other", usersCount: otherTotal }];
      }
      return sortData;
    }
    return [];
  };

  const getRoundedValue = (value: number) => {
    const rounded = Math.round(value * 100) / 100;
    const percentage = rounded % 1 > 0 ? Math.abs(rounded).toFixed(1) : Math.abs(rounded).toString();
    return percentage;
  };

  const { loading } = useQuery<FetchUsersGroupStatsResponse>(FETCH_USERS_GROUP_STATS, {
    fetchPolicy: "no-cache",
    variables: { grouping: groupBy, toDate: dayjs().subtract(1, "year").format(DATE_FORMAT.isoFormatWithoutTime) },
    onCompleted: (response) => {
      const totalHeadCount = modifyResponse(response?.currentYear ?? []).reduce(
        (acc, curr) => acc + curr.usersCount,
        0,
      );
      const modifyLastYearResponse = modifyResponse(response?.lastYear ?? []);

      const modifyData = modifyResponse(response?.currentYear ?? []).reduce((acc, curr, index) => {
        const previousYearCount = modifyLastYearResponse.find((item) => item.name === curr.name)?.usersCount;
        const growth =
          !curr.usersCount && !previousYearCount
            ? 0
            : curr.usersCount && !previousYearCount
            ? 100
            : (curr.usersCount / (previousYearCount as number) - 1) * 100;
        const previousYear = parseFloat(getRoundedValue(growth));
        const currentYearPercentage = (curr.usersCount / totalHeadCount) * 100;
        const currentYear = parseFloat(getRoundedValue(currentYearPercentage));

        acc.push({
          id: index.toString(),
          name: curr.name,
          headCount: curr.usersCount,
          currentYear,
          previousYear,
          growth: growth > 0 ? 1 : 0,
        });
        return acc;
      }, [] as ModifyUserGroupStatsData[]);
      setData(modifyData);
    },
  });

  const color = useMemo(
    () =>
      groupBy === BreakdownFilterEnum.Gender || data.length <= 3
        ? [COLORS.firstCellColor, COLORS.thirdCellColor, COLORS.fourthCellColor]
        : [
            COLORS.firstCellColor,
            COLORS.secondCellColor,
            COLORS.thirdCellColor,
            COLORS.fourthCellColor,
            COLORS.fifthCellColor,
            COLORS.sixthCellColor,
          ],
    [groupBy, data],
  );

  const onMouseHoverHandler = (index: number) => {
    setActiveLabelIndex(index);
    if (document && document.querySelector("#employeeBreakdown div.ant-table-body")) {
      document.querySelector("#employeeBreakdown div.ant-table-body")!.scrollTop = index * 55;
    }
  };

  const onMouseLeaveHandler = () => {
    setActiveLabelIndex(undefined);
  };

  return (
    <StyledContainer data-testid="numberOfEmployeeBreakdown">
      <StyledHeader>
        <Typography.Title level={4} className="m-0">
          Number of employees
        </Typography.Title>
        <Segmented
          options={enumValues(BreakdownFilterEnum).map((l) => ({
            label: breakdownFilterLabel[l],
            value: l,
          }))}
          value={groupBy}
          onChange={(newValue) => {
            setGroupBy(newValue as BreakdownFilterEnum);
          }}
        />
      </StyledHeader>
      {data.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {data.length > 0 && (
        <Row>
          <Col lg={8} xl={8} xxl={10}>
            <BreakdownPieChart
              chartData={data}
              colors={color}
              activeLabelIndex={activeLabelIndex}
              onMouseHoverHandler={onMouseHoverHandler}
              onMouseLeaveHandler={onMouseLeaveHandler}
            />
          </Col>
          <Col lg={16} xl={16} xxl={14}>
            <EmployeeBreakdownTable
              data={data}
              filterBy={groupBy}
              colors={color}
              loading={loading}
              activeLabelIndex={activeLabelIndex}
              onMouseHoverHandler={(index) => setActiveLabelIndex(index)}
              onMouseLeaveHandler={onMouseLeaveHandler}
            />
          </Col>
        </Row>
      )}
    </StyledContainer>
  );
};

export default EmployeesBreakdown;

const StyledContainer = styled(Container)`
  padding: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 11px 19px 20px;
`;
